import React from 'react';
import Bg1 from "../../../../assets/images/report/bg1.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";

export default function Page1(props) {

    const {pageNumber, companyInfo, programInfo, onlyTable = false} = props;

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg1}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={"center"} height={"inherit"}>
            <Typography variant={'h3'} style={{color: "orangered", fontWeight: "normal", fontFamily: `"Lemon/Milk"`}}>
                DQ Seal Report
            </Typography>
            <Typography variant={'h6'} style={{color: "grey", fontWeight: "bold"}}>
                For {companyInfo.NameOrg}
            </Typography>
        </Box>
    </ReportPage>
}
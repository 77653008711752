import React from 'react';
import Bg5 from "../../../../assets/images/report/bg5.png";
import Bg15 from "../../../../assets/images/report/bg15.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {makeStyles} from "@material-ui/core/styles";
import {DQ_DEFINITIONS} from "../../../../utils/constants";


function ListItem(props) {

    const {title} = props;

    return <li style={{fontSize: 32, fontWeight: "lighter"}}>
        <Typography style={{fontSize: 24, color: "orangered", fontWeight: "bold"}}>
            {title}
        </Typography>
    </li>
}

export default function Page15(props) {

    const {pageNumber, codes, onlyTable = false} = props;

    return <ReportPage pageNumber={pageNumber} backgroundImage={onlyTable ? Bg5 : Bg15}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} height={'inherit'} width={'inherit'} gridRowGap={10} px={10}>
            {
                <Box flexBasis={'10%'} >
                    <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 70}}>
                        Enhancement 3: <Typography variant={'h4'} component='span' style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>
                        Increase the Holistic Learning Experience
                    </Typography>
                    </Typography>
                </Box>
            }
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={"space-around"} height={"inherit"} width={'inherit'} gridColumnGap={20}>
                <Box/>
                <Box flexBasis={'40%'} alignSelf={onlyTable ? "flex-start" : "inherit"}>
                    {
                        !onlyTable &&
                        <Typography style={{fontSize: 28, color: "grey", fontWeight: "lighter", textAlign: "left", marginBottom: 20}}>
                            The holistic learning experience of the
                            program can be enhanced by including DQ
                            Competencies in other areas of
                            Connectivity and Citizenship:
                        </Typography>
                    }
                    <ul style={{display: "flex", flexDirection: "column", rowGap: 10, listStyleType: "circle", fontWeight: "lighter"}}>
                        {
                            codes.map((code) => <ListItem title={code}/>)
                        }
                    </ul>
                </Box>
            </Box>
        </Box>
    </ReportPage>
}
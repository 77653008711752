import React from 'react';
import {Typography} from "@material-ui/core";
import Bg6 from "../../../../assets/images/report/bg6.png";
import Box from "@material-ui/core/Box";
import ReportPage from "./PageContainer.report.comp";

function OrgDetailRow(props) {
    const {title, value} = props;
    return <tr>
        <td style={{paddingRight: 20, paddingTop: 10, paddingBottom: 10}}>
            <Typography variant={'h4'} style={{color: "deepskyblue", fontWeight: "bold", fontSize: "22px", textAlign: "right"}}>
                {title}
            </Typography>
        </td>
        <td style={{paddingLeft: 10}}>
            <Typography style={{color: "grey", fontWeight: "lighter", fontSize: "22px", textAlign: "left"}}>
                {value}
            </Typography>
        </td>
    </tr>
}

export default function Page6(props) {

    const {pageNumber, companyInfo, programInfo, onlyTable = false} = props;

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg6}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} height={'inherit'} width={'inherit'} gridRowGap={10} px={10}>
            {
                !onlyTable &&
                <Box flexBasis={'10%'}>
                    <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 70}}>
                        DQ Seal Application: <Typography variant={'h4'} component='span' style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>
                        Aligned
                    </Typography>
                    </Typography>
                </Box>
            }
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justify={'center'} justifyContent={"center"} height={"inherit"} width={'inherit'} pb={15}>
                <Box flexBasis={'20%'}/>
                <Box flexBasis={'60%'}>
                    <table>
                        <OrgDetailRow title={'Organization'} value={companyInfo.NameOrg}/>
                        <OrgDetailRow title={'Contact Person'} value={companyInfo.NameContact}/>
                        <OrgDetailRow title={'Program'} value={programInfo.ProgramName}/>
                        <OrgDetailRow title={'Aims and Objectives of Your Program'} value={programInfo.ProgramAims}/>
                        <OrgDetailRow title={'Venue'} value={programInfo.ProgramVenue}/>
                        <OrgDetailRow title={'Program Delivery'} value={programInfo.ProgramDelivery}/>
                    </table>
                </Box>
            </Box>
        </Box>
    </ReportPage>
}
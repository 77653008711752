import React from 'react';
import Bg5 from "../../../../assets/images/report/bg5.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {makeStyles} from "@material-ui/core/styles";
import {asciiSortCompareFunWithKey} from "../../../../utils/utils";
import {DQ_DEFINITIONS} from "../../../../utils/constants";
import CoverageChart from "./CoverageChart.report.comp";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        "& th,td": {
            border: "1px solid grey",
            padding: "5px 10px",
            fontSize: "18px"
        },
        "& th": {
            padding: "18px 12px",
            textAlign: "center",
            backgroundColor: "deepskyblue",
            color: "white",
        },
        "& th:nth-child(1)": {
            width: "60%"
        },
        "& th:nth-child(2),": {
            width: "20%"
        },
        "& th:nth-child(3)": {
            width: "20%"
        }
    },
}));

function CustomRow(props) {

    const {v1, v2, v3, is_last = false} = props;

    const c0 = {backgroundColor: is_last ? "lightgrey" : "default"}
    const c1 = {color: "grey", fontWeight: is_last ? "bold" : "normal", textAlign: "left"}
    const c2 = {color: "black", fontWeight: "bold", textAlign: "right"}
    return <tr style={c0}>
        <td style={c1}>{v1}</td>
        <td style={c2}>{v2}</td>
        <td style={c2}>{v3}%</td>
    </tr>
}

export default function Page8(props) {

    const {pageNumber, overallCoverage, coverage, onlyTable = false} = props;
    const total_messages = overallCoverage && overallCoverage.grand_total && overallCoverage.grand_total.count ? overallCoverage.grand_total.count : 0;
    const total_dqc = overallCoverage && overallCoverage.data ? overallCoverage.data.length : 0;
    function getDQC(dqc_no) {
        const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
        const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
        const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
        const color = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].color : "#444"
        return {code, title, color};
    }
    function getDQCName(dqc_no) {
        const {title, code} = getDQC(dqc_no);
        return code + (title ? `: ${title}` : '');
    }

    const classes = tableStyles();

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg5}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} height={'inherit'} width={'inherit'} gridRowGap={10}>
            {
                <Box flexBasis={'10%'} px={10}>
                    <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 70}}>
                        DQ Seal Analysis I: <Typography variant={'h4'} component='span' style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>Coverage of DQ Competencies</Typography>
                    </Typography>
                </Box>
            }
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={"flex-start"} height={"inherit"} width={'inherit'}>
                <Box display={'flex'} flexBasis={"50%"} flexDirection={'column'} gridRowGap={20} alignSelf={onlyTable ? "flex-start" : "inherit"}>
                    {
                        !onlyTable &&
                        <Box style={{backgroundColor: "#444", borderTopRightRadius: "20px", padding: 20, paddingLeft: 80}}>
                            <ul style={{listStyleType: "circle", fontWeight: "lighter"}}>
                                <li style={{fontSize: 24, color: "white", fontWeight: "lighter"}}>
                                    <Typography style={{fontSize: 24, color: "white", fontWeight: "lighter"}}>
                                        <span style={{color: "orange", fontWeight: "bold"}}>{total_messages} </span>learning messages were reviewed
                                    </Typography>
                                </li>
                                <li style={{fontSize: 24, color: "white", fontWeight: "lighter"}}>
                                    <Typography style={{fontSize: 24, color: "white", fontWeight: "lighter"}}>
                                        <span style={{color: "orange", fontWeight: "bold"}}>{total_dqc} </span>out of 32 DQ Competencies were found
                                        from the reviewed messages
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    }
                    <Box style={{marginLeft: 100}}>
                        <table className={classes.root} style={{width: "inherit"}}>
                            <tr>
                                <th>DQ Competencies</th>
                                <th># of Messages</th>
                                <th>%</th>
                            </tr>
                            {
                                coverage && coverage.data &&
                                coverage.data.sort(asciiSortCompareFunWithKey('dqc_no')).map((item) =>
                                    <CustomRow v1={getDQCName(item.dqc_no)} v2={item.count} v3={item.percent}/>
                                )
                            }
                            {
                                coverage && coverage.grand_total &&
                                <CustomRow v1={"Total"} v2={coverage.grand_total.count} v3={coverage.grand_total.percent} is_last={true}/>
                            }
                        </table>
                    </Box>
                </Box>
                <Box display={'flex'} flexBasis={"50%"} alignSelf={'center'} justifyContent={"center"} alignItems={'center'} height={"100%"} width={"100%"}>
                    {
                        !onlyTable &&
                        overallCoverage && overallCoverage.data &&
                        <CoverageChart coverage={
                            overallCoverage.data.sort(asciiSortCompareFunWithKey('dqc_no')).map((item) => ({
                                ...item,
                                ...getDQC(item.dqc_no)
                            }))
                        }/>
                        // <Box width={"500px"} height={"500px"} borderRadius={"50%"} bgcolor={"yellowgreen"} textAlign={'center'}/>
                    }
                </Box>
            </Box>
        </Box>
    </ReportPage>
}
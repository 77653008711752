import { useEffect } from 'react';
import {getAnalysisService} from "../../../services/app.service";
import {ANALYSIS_TYPES} from "../../../utils/constants";
import {asciiSortCompareFunWithKey, getMapFromObjectListByKey} from "../../../utils/utils";

export function useDepth(application_id, setAnalysis) {

    const loadAnalysis = async () => {
        try {
            const result = await getAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_6_DEPTH);
            if(!result.error) {
                const result2 = await getAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_7_ALIGNMENT);
                if(!result.error) {
                    const depth = result.data;
                    const alignment = result2.data;
                    depth.data.sort(asciiSortCompareFunWithKey('dqc_no'))
                    const alignment_map = getMapFromObjectListByKey(alignment.data, "mb_no");
                    const extended_depth = {...depth, data: []};
                    const is_first_mb_map = {}
                    for(const item of depth.data) {
                        const {mb_no, dqc_no} = item;
                        const mb_name = alignment_map[mb_no] ? alignment_map[mb_no]["level6_full_name"] : ""
                        if(!is_first_mb_map[dqc_no]) is_first_mb_map[dqc_no] = mb_no;
                        const is_first_mb = is_first_mb_map[dqc_no] === mb_no;
                        extended_depth.data.push({...item, mb_name, is_first_mb})
                    }
                    setAnalysis(extended_depth);
                }
            }
        }
        catch (e) {
            console.error(e);
            // already toasted the error
        }
    }

    useEffect( ()=> {
        loadAnalysis().then((res) => `level 6 depth fetch initiated, ${res}`);
    }, [])
}
export const PROGRAM_STATUS = [
    {
        "value": "Yes",
        "label": "The program is still in use today."
    },
    {
        "value": "No",
        "label": "The program is no longer in use today"
    }
];

export const PROGRAM_STATUS_MAP = {
    "Yes": {
        "label": "The program is still in use today."
    },
    "No": {
        "label": "The program is no longer in use today"
    }
};
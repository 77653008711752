export const PROGRAM_TYPES = [
    {
        "value": "EdandTrain",
        "label": "Education and training"
    },
    {
        "value": "Assess",
        "label": "Assessment"
    },
    {
        "value": "Research",
        "label": "Research"
    },
    {
        "value": "Game",
        "label": "Gaming"
    },
    {
        "value": "Tech",
        "label": "Other Media and Technology"
    },
    {
        "value": "Others",
        "label": "Others"
    }
];

export const PROGRAM_TYPES_MAP = {
    "EdandTrain": {
        "label": "Education and training"
    },
    "Assess": {
        "label": "Assessment"
    },
    "Research": {
        "label": "Research"
    },
    "Game": {
        "label": "Gaming"
    },
    "Tech": {
        "label": "Other Media and Technology"
    },
    "Others": {
        "label": "Others"
    }
};
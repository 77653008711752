import React from 'react';
import Bg12 from "../../../../assets/images/report/bg12.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";

function EnhancementBox(props) {
    const {index, title, hint,pageNumber} = props;
    return <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} border={"1px solid black"} textAlign={'center'} style={{aspectRatio: "1/1"}}>
        <Typography style={{color: "orangered", fontWeight: "bold", fontSize: "22px"}}>
            Enhancement {index}:
        </Typography>
        <Typography style={{color: "grey", fontSize: "22px", paddingBottom: "10px"}}>
            {title}
        </Typography>
        <Typography style={{color: "#2F5496", fontSize: "16px"}}>
            {hint} {pageNumber})
        </Typography>
    </Box>
}

export default function Page12(props) {

    const {pageNumber,enhancement1PageNo,enhancement2PageNo,enhancement3PageNo, onlyTable = false} = props;

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg12}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} height={'inherit'} width={'inherit'} gridRowGap={10}>
            {
                <Box display={'flex'} flexDirection={'row'} flexBasis={'10%'} justifyContent={'flex-start'} height={'inherit'} width={'inherit'}>
                    <Box flexBasis={'22%'}/>
                    <Box>
                        <Typography variant={'h3'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 75}}>
                            Areas of Enhancement I:
                        </Typography>
                        <Typography variant={'h3'} component='span' style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>
                            Enhancement of Learning Content
                        </Typography>
                    </Box>
                </Box>
            }
            <Box height={"inherit"} width={'inherit'} paddingX={15}>
                <Typography style={{color: 'grey', fontWeight: "lighter", fontSize: "18px", marginTop: 30, marginBottom: 20}}>
                    Are you interested in enhancing your programs to cover more DQ MBs to provide more holistic, high-quality education for your users? DQ can help you license out various types of best-of-breed learning
                    content related to the DQ MBs you are interested in enhancing.
                </Typography>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'stretch'} gridColumnGap={20}>
                    <EnhancementBox
                        index={1}
                        title={"Increase the Depth of Learning Messages"}
                        hint={"(depth of MB Alignment - p."}
                        pageNumber={pageNumber+1}
                    />
                    <EnhancementBox
                        index={2}
                        title={"Increase the Breadth of Learning Messages"}
                        hint={"(# of qualified MBs - p."}
                        pageNumber={enhancement1PageNo+1}
                    />
                    <EnhancementBox
                        index={3}
                        title={"Increase the Holistic Learning Experience"}
                        hint={"(# of DQ Competencies- p."}
                        pageNumber={enhancement2PageNo+1}
                    />
                </Box>
            </Box>
        </Box>
    </ReportPage>
}
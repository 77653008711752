import React, { useEffect, useRef, useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Container,
    Typography,
    Card,
    CardContent,
    Select,
    Grid,
    Divider,
    Box,
    IconButton,
    TextField,
    FormControl,
} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {APP_STATUS, ASSESSMENT_REQ_TYPES, TOAST_TYPES} from "../../../utils/constants";
import {postAddAssessmentReq} from "../../../services/certify.service";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {toast} from "../../../utils/utils";
import CertifiedLogo from "../../../assets/images/Certified_logo.png"
import preAssessment from "../../../assets/images/preAssessment.png"
import postAssessment from "../../../assets/images/postAssessment.png"
import citizenshipTest from "../../../assets/images/citizenship-icon.png"
import parentingTest from "../../../assets/images/digitalParenting.png"
import customPreAssessment from "../../../assets/images/customPreAssessment.png"
import customPostAssessment from "../../../assets/images/customPostAssessment.png"

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
        textAlign:'center',
    },
    paper: {
        position: 'absolute',
        width: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    instruction: {
        margin: theme.spacing(2, 3, 2, 0),
    },
    button: {
        width: '15%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 3, 1, 3),
        backgroundColor: theme.palette.info.main,
        fontSize: '20px',
        color: theme.palette.background.default,
        border: 'none',
        borderRadius: '5px',
        margin:theme.spacing(2),
    },
    mt: {
        marginTop: theme.spacing(2),
    },
    mb: {
        marginBottom: theme.spacing(2),
    },
    listItem: {
        paddingLeft: theme.spacing(7),
        position: 'relative',
    },
    bullet: {
        content: '""',
        position: 'absolute',
        left: theme.spacing(4),
        top: '50%',
        transform: 'translateY(-50%)',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        color:'black',
    },
    card: {
        textAlign:'left',
        width:'80px',
        flex: '1 0 auto',
        '&:not(:last-child)': {
            marginRight: 0,
        },
        boxShadow: 'none',
        backgroundColor: 'transparent',
    },
    verticalDivider: {
        borderRight: `2px solid lightgrey`,
        height: '38vh',
        margin:'0 2%',
    },
    nestedCard: {
        borderRadius: theme.spacing(1),
        border: `2px solid black`,
        boxShadow: 'none',
        height:'17vh',
        margin:theme.spacing(1),
    },
    nestedCardContainer: {
        display: 'flex',
        flexWrap:'wrap',
        marginTop:'10%',
    },
    nestedButton:{
        width:'90%',
        padding: theme.spacing(1, 2, 1, 2),
        height:'24vh',
        backgroundColor: theme.palette.background.paper,
        fontSize: '14px',
        color: "black",
        border: '1px solid black',
        borderRadius: '15px',
        margin:theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.info.main,
            color:theme.palette.background.default,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '40vw',
        margin: '0 auto',
        marginTop: theme.spacing(2),
        textAlign:'left',
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    activeButton: {
        backgroundColor: theme.palette.info.main,
        color:theme.palette.background.default,
    },
    mySpan: {
    display: 'block',
    },
}));


function AssessmentModule(props){
    const classes = useStyles();
    const {application_id, status, updateStatus} = props;
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        noOfUsers: 0,
        onlineSystem: '',
        requirements: '',
        noOfCountries: 0,
    });
    const [selectedCard, setSelectedCard] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleCardClick = (buttonIndex) => {
        if (selectedCard === buttonIndex) {
            setSelectedCard(null);
            setShowForm(false);
        } else {
            setSelectedCard(buttonIndex);
            setShowForm(true);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const submitAddAssessmentReq = async () => {

        const postData = {
            'application_id': application_id,
            'assess_type': selectedCard,
            'expected_users': formData.noOfUsers,
            'client_own_platform_info': formData.onlineSystem,
            'client_requirements_info': formData.requirements,
            'intended_number_of_countries': formData.noOfCountries
        }

        try {
            setLoading(true);
            const result = await postAddAssessmentReq(postData);
            if (!result.error) {
                toast(TOAST_TYPES.SUCCESS, "Request Submitted", "Successfully submitted request, we will contact you back soon.")
                if (result.data.success) {
                    updateStatus(result.data.status);
                }
            }
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
            //already toasted
        }
        // make the post call to => /certify/assessment/request
    }

    return (
        <Container className={classes.container}>
            <Container className={classes.container}>
                <Typography component="h1" variant="h5" color="inherit" style={{ fontWeight: 'bold', textAlign:'center' }}>
                    Add Assessment
                </Typography>
                <Typography className={classes.instruction} style={{textAlign:'left' }} variant="body1">Please select the type of assessment you would like to include into your program. You can also choose if you would like a pre-post version that helps with measuring your users’ efficacy before and after the program.
                </Typography>
                <Box display="flex" alignItems="center">
                    <Card className={classes.card}>
                        <CardContent >
                            <Typography variant="h6" style={{textAlign:'center',}}>Pre-Selected Assessments</Typography>
                            <Typography>These assessments are pre-selected and grouped  based on your program’s alignment to the DQ Competencies and  ready-to-go. </Typography>
                        <Grid container className={classes.nestedCardContainer}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Button
                                id={`button-1`}
                                className={`${classes.nestedButton} ${
                                    (selectedCard === ASSESSMENT_REQ_TYPES.PRE_SELECT_PRE_POST && showForm) ? classes.activeButton : ''
                                }`}
                                onClick={() => handleCardClick(ASSESSMENT_REQ_TYPES.PRE_SELECT_PRE_POST)}
                            >
                                <div  style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                                    <img
                                        src={preAssessment}
                                        alt="preAssessmentIcon"
                                        width={48}
                                        height={48}
                                        style={{marginBottom:'20%', marginTop:'-10%'}}
                                    />
                                    <Typography variant="button" >DQ-Selected Pre and Post Assessment</Typography>
                                </div>
                            </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12} lg={6}>
                            <Button
                                id={`button-2`}
                                className={`${classes.nestedButton} ${
                                    (selectedCard === ASSESSMENT_REQ_TYPES.PRE_SELECT_POST_ONLY && showForm) ? classes.activeButton : ''
                                }`}
                                onClick={() => handleCardClick(ASSESSMENT_REQ_TYPES.PRE_SELECT_POST_ONLY)}
                            >
                                <div  style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                  <img
                                      src={postAssessment}
                                      alt="postAssessmentIcon"
                                      width={48}
                                      height={48}
                                  />
                                <Typography variant="button">DQ-Assessment Post Assessment Only</Typography>
                                </div>
                            </Button>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                    <Divider className={classes.verticalDivider} />
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6" style={{textAlign:'center',}}>Standard Assessments</Typography>
                            <Typography>These are standardized assessments for anyone to use and are ready-to-go that cover a broad set of topics or specific to targeted audiences.</Typography>
                        <Grid container className={classes.nestedCardContainer}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Button
                                id={`button-3`}
                                className={`${classes.nestedButton} ${
                                    (selectedCard === ASSESSMENT_REQ_TYPES.STANDARD_DCT && showForm) ? classes.activeButton : ''
                                }`}
                                onClick={() => handleCardClick(ASSESSMENT_REQ_TYPES.STANDARD_DCT)}
                            >
                                <div  style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                    <img
                                        src={citizenshipTest}
                                        alt="citizenshipTestIcon"
                                        width={48}
                                        height={48}
                                        style={{marginBottom:'20%', marginTop:'-20%'}}
                                    />
                                    <Typography variant="button">Digital Citizenship Test</Typography>
                                </div>
                            </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Button
                                id={`button-4`}
                                className={`${classes.nestedButton} ${
                                    (selectedCard === ASSESSMENT_REQ_TYPES.STANDARD_DPT && showForm) ? classes.activeButton : ''
                                }`}
                                onClick={() => handleCardClick(ASSESSMENT_REQ_TYPES.STANDARD_DPT)}
                            >
                                <div  style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                    <img
                                        src={parentingTest}
                                        alt="parentingTestIcon"
                                        width={48}
                                        height={48}
                                        style={{marginBottom:'20%', marginTop:'-20%'}}
                                    />
                                    <Typography variant="button"> Digital Parenting Test</Typography>
                                </div>
                            </Button>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                    <Divider className={classes.verticalDivider} />
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6" style={{textAlign:'center',}}>Custom
                                Assessment
                            </Typography>
                            <Typography>This custom assessment is based on your specific needs of what you want to measure. Please take note that this does require more time to develop.
                            </Typography>
                        <Grid container className={classes.nestedCardContainer}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Button
                                id={`button-5`}
                                className={`${classes.nestedButton} ${
                                    (selectedCard === ASSESSMENT_REQ_TYPES.CUSTOM_PRE_POST && showForm) ? classes.activeButton : ''
                                }`}
                                onClick={() => handleCardClick(ASSESSMENT_REQ_TYPES.CUSTOM_PRE_POST)}>
                                <div  style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                    <img
                                        src={customPreAssessment}
                                        alt="customPreAssessmentIcon"
                                        width={48}
                                        height={48}
                                        style={{marginBottom:'20%', marginTop:'-20%'}}
                                    />
                                    <Typography variant="button">  Custom Pre and Post Assessment</Typography>
                                </div>

                            </Button>
                        </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Button
                                id={`button-6`}
                                className={`${classes.nestedButton} ${
                                    (selectedCard === ASSESSMENT_REQ_TYPES.CUSTOM_POST_ONLY && showForm) ? classes.activeButton : ''
                                }`}
                                onClick={() => handleCardClick(ASSESSMENT_REQ_TYPES.CUSTOM_POST_ONLY)}
                            >
                                <div  style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                    <img
                                        src={customPostAssessment}
                                        alt="customPostAssessmentIcon"
                                        width={48}
                                        height={48}
                                        style={{marginBottom:'20%', marginTop:'-20%'}}
                                    />
                                    <Typography variant="button">Custom Post Assessment Only</Typography>
                                </div>

                            </Button>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                </Box>
                {showForm && (
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <FormControl>
                            <Typography># of Expected Users</Typography>
                            <TextField
                                className={classes.textField}
                                id="noOfUsers"
                                name="noOfUsers"
                                type="number"
                                value={formData.noOfUsers}
                                onChange={handleInputChange}
                                variant="outlined"
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <Typography>Do you have your own online system/platform to integrate the assessment? If yes, please elaborate.</Typography>
                            <TextField
                                className={classes.textField}
                                name="onlineSystem"
                                value={formData.onlineSystem}
                                onChange={handleInputChange}
                                variant="outlined"
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <Typography>Do you have any requirements or specific metrics that you want to have? If yes, please elaborate.
                            </Typography>
                            <TextField
                                className={classes.textField}
                                name="requirements"
                                value={formData.requirements}
                                onChange={handleInputChange}
                                variant="outlined"
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <Typography> How many countries are you intending to roll this out to?</Typography>
                            <TextField
                                className={classes.textField}
                                name="noOfCountries"
                                type="number"
                                value={formData.noOfCountries}
                                onChange={handleInputChange}
                                variant="outlined"
                                required
                            />
                        </FormControl>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={submitAddAssessmentReq}
                            disabled={status > APP_STATUS.READY_TO_CERTIFY}
                        >
                            Submit
                        </Button>
                    </form>
                )}
            </Container>


            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
}

function CertifiedModule(props) {

    const {status} = props;

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [isAssessmentModuleShown, setIsAssessmentModuleShown] = useState(false);


    useEffect(() => {
    }, []);
    const handleClick = event => {
        setIsAssessmentModuleShown(current => !current);
    };

    return (
        <>
            {
                status === APP_STATUS.READY_TO_CERTIFY ?
                    <Container className={classes.container}>
                        {!isAssessmentModuleShown ? (
                            <div>
                                <Container className={classes.container}>
                                    <Typography component="h1" variant="h5" color="inherit" style={{ fontWeight: 'bold', textAlign:'center' }}>
                                        Get DQ Certified
                                    </Typography>
                                    <Grid container>
                                        <Grid item md={3}>
                                            <img src={CertifiedLogo}></img>
                                        </Grid>
                                        <Grid item md={9}>
                                    <Typography className={classes.instruction} style={{textAlign:'left' }} variant="body1"> Unlock the opportunity to get DQ Certified by incorporating an impact tracker and assessment tools into your program. This will enable you to measure the efficacy of your program and provides your users with insights to identify areas of improvement.
                                        <Typography className={classes.instruction} variant="body1">
                                            Powered by DQ Certified offers:
                                        </Typography>
                                        <List sx={{ listStyleType: `disc`, pl: 4 }} style={{marginTop:'-25px'}}>
                                            <ListItem className={classes.listItem} sx={{ display: 'list-item' }}>
                                                <FiberManualRecordIcon style={{fontSize:'12px', marginRight:'10px', marginBottom:'17px'}}/>
                                                <ListItemText primary="A tailored assessment tool with scoring functionality, based on your program alignment, accessible through our portal or integratable via API into your existing system, to be delivered to your users." />
                                            </ListItem>
                                            <ListItem className={classes.listItem} sx={{ display: 'list-item' }}>
                                                <FiberManualRecordIcon style={{fontSize:'12px', marginRight:'10px', marginBottom:'17px'}}/>
                                                <ListItemText primary="An analytics dashboard to monitor your program’s impact by demographics such as age and gender, along with comparisons to national and global averages." />
                                            </ListItem>
                                            <ListItem className={classes.listItem} sx={{ display: 'list-item' }}>
                                                <FiberManualRecordIcon style={{fontSize:'12px', marginRight:'10px'}}/>
                                                <ListItemText primary="Increased brand awareness through inclusion in the DQ Index, acknowledging your program’s contribution." />
                                            </ListItem>
                                        </List>
                                    </Typography>
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleClick}
                                >
                                    Next
                                </Button>
                            </div>
                        ) :   <AssessmentModule {...props} />}
                        <LoaderWithBackDrop loading={loading} />
                    </Container> : <Container className={classes.container}>
                        <Grid container justifyContent="center" alignItems="center">
                        <Grid item md={9} style={{textAlign:'center'}}>
                        <Typography component="h1" variant="h5" color="inherit" style={{ fontWeight: 'bold', textAlign:'center' }}>
                            {status === APP_STATUS.ASSESSMENTS_READY ? "Assessment is Available Now!" : "Your request has been submitted and is currently under review. You will receive an email notification once the assessment is ready. Thank you!\n"}
                        </Typography>
                        </Grid>
                        </Grid>
                    </Container>
            }

        </>
    );
}

export default CertifiedModule;
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Container,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Typography,
    Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, useFormikContext } from "formik";
import axios from "axios";
import { addProgramInfoService } from "../../services/app.service";
import { initResetPassword } from "../../services/auth.service";
import { PROGRAM_TYPES } from "../../utils/META/cat_106";
import { PROGRAM_TARGETS } from "../../utils/META/cat_108";
import { TARGET_AGE } from "../../utils/META/cat_109";
import { PROGRAM_VENUE } from "../../utils/META/cat_111";
import { PROGRAM_DURATION } from "../../utils/META/cat_112";
import { PROGRAM_STATUS } from "../../utils/META/cat_121";
import { toast } from "../../utils/utils";
import {SEAL_TYPE, TOAST_TYPES} from "../../utils/constants";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import InfoIcon from '@material-ui/icons/Info'
import {SEAL_TYPES} from "../../utils/META/cat_seal_types";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: "100%",
    },
    section: {
        marginBottom: theme.spacing(4),
    },
    instruction: {
        margin: theme.spacing(3,3,3,0),
    },

}));

function validateWordLength(value, max_length) {
    const length = value ? value.match(/\w+/g).length : 0;
    const error = length > max_length;
    const error_message = error ? 'Word limit exceeded' : undefined;
    return { length, max_length, error, error_message };
}

function MaxLengthHelperText({ length, max_length, error, error_message }) {
    return <Box display={'flex'} flexDirection={'row'}>
        {error && <Box mr={'auto'}>{error_message}</Box>}
        <Box ml={'auto'}>{length}/{max_length}</Box>
    </Box>
}

const FormTextField = ({ label, name, required = true, ...rest }) => (
    <Field
        as={TextField}
        label={label}
        name={name}
        fullWidth
        required={required}
        margin="normal"
        variant="outlined"
        {...rest}
    />
);

const FormSelect = ({ label, name, options }) => (
    <FormControl fullWidth variant="outlined" margin="normal" required>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Field as={Select} label={label} name={name}>
            {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Field>
    </FormControl>
);

const FormComponent = ({ disableSubmit }) => {

    const classes = useStyles();
    const { values } = useFormikContext(); // get current form values
    const descValidProps = validateWordLength(values.ProgramDescription, 500);


        // if(process.env.REACT_APP_NAME==='LOCAL' || process.env.REACT_APP_NAME==='DEVELOP') {
        //     console.log("Default Values Loaded for Local/Development Environment")
        // }
        // else  {
        //     console.log("Default Values Loaded for Production/Stage/etc.")
        // }

    return (
        <Form>
            <div className={classes.section}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Program Description
                </Typography>
                <Typography className={classes.instruction} variant="body1"><InfoIcon />  Please add the following details about your program to help us understand your program objectives, audience, time, and delivery methods.
                    </Typography>
                <FormTextField label="Title of the program" name="ProgramName" />
                <FormSelect
                    label="Type of DQ-SEAL"
                    name="SealType"
                    options={SEAL_TYPES}
                />
                <FormSelect
                    label="Your program is related to"
                    name="ProgramType"
                    options={PROGRAM_TYPES}
                />
                {values.ProgramType === 'Others' && (
                    <FormTextField
                        label="Please specify your program type."
                        name="ProgramTypeOther"
                    />
                )}
                <FormTextField label="Aims and objectives of your program" name="ProgramAims" />
                <FormSelect
                    label="Target audience of your program"
                    name="ProgramTarget"
                    options={PROGRAM_TARGETS}
                />
                {
                    values.ProgramTarget === 'Students' && (
                        <FormSelect
                            label="Age group of students"
                            name="TargetAge"
                            options={TARGET_AGE}
                        />
                    )
                }
                <FormTextField label="Target countries" name="TargetCountry" />
                <FormTextField
                    label="Please give a short description of your program [no more than 500 words]"
                    name="ProgramDescription"
                    multiline={true}
                    helperText={<MaxLengthHelperText {...descValidProps} />}
                    error={descValidProps.error}
                    validate={() => descValidProps.error_message}
                />
                <FormTextField label="Please describe your program delivery" name="ProgramDelivery" />
                <FormSelect
                    label="Venue where program is conducted"
                    name="ProgramVenue"
                    options={PROGRAM_VENUE}
                />
                <FormSelect
                    label="Time taken for the program to be completed (in hours)"
                    name="ProgramDuration"
                    options={PROGRAM_DURATION}
                />
                <FormTextField
                    label="Resources required for program to be carried out"
                    name="RefResource"
                />
                <FormTextField label="Year of implementation launch" name="StartYear" type="number" />
                <FormSelect
                    label="What is the status of your program?"
                    name="Status"
                    options={PROGRAM_STATUS}
                />
                <FormTextField label="Link or Mobile app" name="Links" />
                <FormTextField label="Price of the Program (Average Cost per Individual)" name="ProgramPrice" />
            </div>
            <div className={classes.section}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Program Impact
                </Typography>
                    <Typography className={classes.instruction} variant="body1"><InfoIcon />  Please add the following details about your program impact and frameworks utilized.
                    </Typography>
                <FormTextField
                    label="Please describe how you would define the success of your program:"
                    name="Success"
                />
                <FormTextField
                    label="Please describe how you would measure the success and show the efficacy of your program:"
                    name="SuccessMeasure"
                />
                <FormTextField
                    label="Please describe how you would ensure that the program’s objective has been met:"
                    name="KPITrack"
                />
                <FormTextField
                    label="If you have a weblink that best describes your program, please provide:"
                    name="RefLink"
                    required={false}
                />
                <FormTextField
                    label="If there is another national or international framework or curriculum on digital literacy, skills and readiness that your program aligns with, please state:"
                    name="RefFramework"
                    required={false}
                />
            </div>
            <Button type="submit" variant="contained" color="primary" disabled={disableSubmit}>
                Submit
            </Button>
        </Form>
    );

};

const CreateApplication = () => {
    const classes = useStyles();
    const initialValues = {
        ProgramName: 'Sample Program',
        SealType: SEAL_TYPE.ALIGN,
        ProgramType: 'Research',
        ProgramTypeOther: '',
        ProgramAims: 'Sample aims and objectives',
        ProgramTarget: 'Gamers',
        TargetAge: '',
        TargetCountry: 'United States',
        ProgramDescription: 'Sample program description',
        ProgramDelivery: 'ASAP',
        ProgramVenue: 'Homebased',
        ProgramDuration: '30min-1hr',
        RefResource: 'Sample resources',
        StartYear: '2023',
        Status: 'Yes',
        Links: 'http://example.com',
        ProgramPrice: '50',
        Success: 'Sample success definition',
        SuccessMeasure: 'Sample success measure',
        KPITrack: 'Sample KPI tracking',
        RefLink: 'http://example.com',
        RefFramework: 'Framework Reference link'
    }

    const emptyInitialValues = {
        ProgramName: '',
        SealType: '',
        ProgramType: '',
        ProgramTypeOther: '',
        ProgramAims: '',
        ProgramTarget: '',
        TargetAge: '',
        TargetCountry: '',
        ProgramDescription: '',
        ProgramDelivery: '',
        ProgramVenue: '',
        ProgramDuration: '',
        RefResource: '',
        StartYear: '',
        Status: '',
        Links: '',
        ProgramPrice: '',
        Success: '',
        SuccessMeasure: '',
        KPITrack: '',
        RefLink: '',
        RefFramework: ''
    }

    const history = useHistory();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (values) => {
        setDisableSubmit(true);
        try {
            setLoading(true);
            const result = await addProgramInfoService(values);
            console.log('result.data -> ', result.data);
            // redirect to login page here
            toast(TOAST_TYPES.SUCCESS, "Program Added", "Successfully added the program information for review, will update you shortly " +
                "regarding the status, proceed to payment section.")
            history.push('/application/list');
        } catch (e) {
            // already toasted the error
            setLoading(false);
        }
        setDisableSubmit(false);
        setLoading(false);
    };

    return (
        <Container className={classes.container}>
            <Formik initialValues={(process.env.REACT_APP_NAME==='LOCAL') ? initialValues : emptyInitialValues} onSubmit={handleSubmit}>
                <FormComponent disableSubmit={disableSubmit} />
            </Formik>
            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
};

export default CreateApplication;

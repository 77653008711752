export const PROGRAM_TARGETS = [
    {
        "value": "AcademicsResearchers",
        "label": "Academics / Researchers"
    },
    {
        "value": "Gamers",
        "label": "Gamers"
    },
    {
        "value": "General",
        "label": "General public"
    },
    {
        "value": "CivilService",
        "label": "Civil Service"
    },
    {
        "value": "Clients",
        "label": "My organization's clients"
    },
    {
        "value": "Employees",
        "label": "My organization's employees"
    },
    {
        "value": "Parents",
        "label": "Parents"
    },
    {
        "value": "Schoolleaders",
        "label": "School leaders"
    },
    {
        "value": "Students",
        "label": "School Students"
    },
    {
        "value": "Teachers",
        "label": "Teachers"
    },
    {
        "value": "Seniors",
        "label": "Seniors"
    },
    {
        "value": "Workforce",
        "label": "Workforce"
    },
    {
        "value": "Kids",
        "label": "Children under 13 YO"
    },
    {
        "value": "Teens+",
        "label": "13YO and above"
    },
    {
        "value": "Children",
        "label": "Children under 19 YO"
    }
];

export const PROGRAM_TARGETS_MAP = {
    "AcademicsResearchers": {
        "label": "Academics / Researchers"
    },
    "Gamers": {
        "label": "Gamers"
    },
    "General": {
        "label": "General public"
    },
    "CivilService": {
        "label": "Civil Service"
    },
    "Clients": {
        "label": "My organization's clients"
    },
    "Employees": {
        "label": "My organization's employees"
    },
    "Parents": {
        "label": "Parents"
    },
    "Schoolleaders": {
        "label": "School leaders"
    },
    "Students": {
        "label": "School Students"
    },
    "Teachers": {
        "label": "Teachers"
    },
    "Seniors": {
        "label": "Seniors"
    },
    "Workforce": {
        "label": "Workforce"
    },
    "Kids": {
        "label": "Children under 13 YO"
    },
    "Teens+": {
        "label": "13YO and above"
    },
    "Children": {
        "label": "Children under 19 YO"
    }
};
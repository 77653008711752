import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Box,
    Link,
    TextField,
    InputAdornment,
    Typography,
    Paper,
    Card,
    CardContent,
    Grid,
    Divider
} from '@material-ui/core';
import {
    getApplicationDataService, getApplicationService, getAppPaymentService,
    updateApplicationDataService, uploadMedia, updateStripeIntentService,
} from "../../../services/app.service";
import Button from "@material-ui/core/Button";
import {APP_STATUS, LOCAL_STORAGE_KEYS, PAYMENT_STATUS, TOAST_TYPES} from "../../../utils/constants";
import axios from "axios";
import {toast, toastWarning} from "../../../utils/utils";
import {DropzoneDialog} from "material-ui-dropzone";
import LoaderBackDropWithPercentage from "../../../components/LoaderWithBackDrop/LoaderBackDropWithPercentage.comp";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import InfoIcon from '@material-ui/icons/Info'
import StripePayment from "../components/StripePayment.comp";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    image: {
        height: '50vh',
        objectFit: 'contain',
    },
    instruction: {
        margin: theme.spacing(3, 3, 3, 0),
    },
    button: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 3, 1, 3),
        backgroundColor: theme.palette.info.main,
        fontSize: '15px',
        border: '2px solid grey',
        borderRadius: '0px',
        color: theme.palette.background.default,
        '&:hover': {
            color: theme.palette.background.default,
            backgroundColor: theme.palette.info.main,
        },
    },
    verticalDivider: {
        borderRight: `2px solid lightgrey`,
        height: '40vh',
        margin:'0 2% 0 0',
    },
    label: {
        textAlign: 'left',
        // marginRight: theme.spacing(2),
    },
    value: {
        textAlign: 'left',
        marginTop: theme.spacing(0.8),
        // marginLeft: theme.spacing(2),
    },
}));

function UploadPaymentProofFileDialogue(props) {

    const { openDialogue, dialogueCloseHandler, successCB } = props;

    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const FileDialogueHandleSave = async (files) => {
        setUploading(true);
        const formData = new FormData();
        formData.append('media', files[0]);
        try {
            const result = await uploadMedia(formData,  progressEvent => {
                const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 99);
                setUploadProgress(uploadPercentage);
            })
        if(result.data) {
                const {media_path} = result.data;
                setUploadProgress(100);
                setTimeout(() => {
                    setUploading(false);
                    setUploadProgress(0);
                    successCB(media_path);
                }, 200);
            }
        }
        catch (e) {
            console.error("Error uploading file", e);
            dialogueCloseHandler();
        }
    }

    return (
        <>
            <DropzoneDialog
                open={openDialogue}
                onSave={FileDialogueHandleSave}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', '.pdf']}
                showPreviews={true}
                maxFileSize={5000000}
                filesLimit={1}
                onClose={dialogueCloseHandler}
            />
            {
                <LoaderBackDropWithPercentage
                    loading={uploading}
                    value={uploadProgress}
                />
            }
        </>
    );
}

function LabelValuePair(props) {

    const classes = useStyles();
    const {label, value} = props;

    return <Grid item xs={12}>
        <Grid container justify="center" alignItems="flex-start">
            <Grid item xs={6}>
                <Typography variant="h6" className={classes.label}>{label}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body1" className={classes.value}>{value}</Typography>
            </Grid>
        </Grid>
    </Grid>
}

function PaymentModule(props) {
    const {application_id, status, updateStatus} = props;
    const [openDialogue, setOpenDialogue] = useState(false);
    const classes = useStyles();
    const [appData, setAppData] = useState({});
    const [paymentAmount, setPaymentAmount] = useState("");
    const [payment, setPayment] = useState(undefined);
    const [paymentFetched, setPaymentFetched] = useState(false);
    const [loading, setLoading] = useState(false);

    const loadAppData = async () => {
        try {
            setLoading(true);
            const result = await getApplicationDataService(application_id);
            if (!result.error) {
                if (result.data.app_data) {
                    setAppData(result.data.app_data);
                }
                setLoading(false);
            }

        } catch (e) {
            // already toasted the error
            setLoading(false);
        }
    }

    async function retrieveApplication() {
        try {
            setLoading(true);
            const result = await getApplicationService(application_id);
            if(!result.error && result.data) {
                const app = result.data;
                const {amount} = app;
                setPaymentAmount(amount.toString());
                setLoading(false);
            }
        }
        catch (e) {
            console.error(e)
            setLoading(false);
        }
    }

    useEffect(() => {
        loadAppData().then((res) => `fetch initiated, ${res}`);
        retrieveApplication().then();
    }, [])

    async function loadPayment() {
        const result = await getAppPaymentService(application_id);
        if(result.data) {
            const {payment} = result.data;
            setPayment(payment);
            setPaymentFetched(true);
        }
        return true;
    }

    async function updateStripePayment(paymentIntent) {
        const result = await updateStripeIntentService(application_id, paymentIntent);
        if(result.data) {
            const {payment, status} = result.data;
            if(payment.status === PAYMENT_STATUS.SUCCESSFUL) {
                updateStatus(status);
            }
            setPayment(payment)
            setPaymentFetched(true);
        }
        return true;
    }

    function removeUrlParameter(paramKey) {
        const url = window.location.href

        const r = new URL(url)
        r.searchParams.delete(paramKey)
        const newUrl = r.href
        window.history.pushState({ path: newUrl }, '', newUrl)
    }

    useEffect( () => {
        const paymentIntent = new URLSearchParams(window.location.search).get("payment_intent");
        if(paymentIntent) {
            updateStripePayment(paymentIntent).then(() => console.log(`stripe payment updated`)).catch((e) => console.error(`stripe payment update failed, ${e}`))
        } else {
            loadPayment().then(() => console.log(`app payment fetched`)).catch((e) => console.error(`app payment fetch failed, ${e}`))
        }
        removeUrlParameter('payment_intent');
        removeUrlParameter('payment_intent_client_secret')
    }, [])

    useEffect(() => {
        const redirectStatus = new URLSearchParams(window.location.search).get("redirect_status");
        if(redirectStatus) {
            switch (redirectStatus) {
                case "succeeded":
                    toast(TOAST_TYPES.SUCCESS, "Payment", "Payment successful");
                    break;
                case "processing":
                    toast(TOAST_TYPES.WARN, "Payment", "Payment is in processing");
                    break;
                case "requires_payment_method":
                    toast(TOAST_TYPES.DANGER, "Payment", "Payment was not successful, try again");
                    break;
                default:
                    toast(TOAST_TYPES.DANGER, "Payment", "Something went wrong");
                    break;
            }
        }
        removeUrlParameter('redirect_status');
    }, [])

    const addFilePathToApplicationData = async (file_path) => {
        try {
            setLoading(true);
            const result = await updateApplicationDataService(application_id, file_path);
            if(!result.error) {
                toast(TOAST_TYPES.SUCCESS, "Uploaded", "Successfully uploaded the proof, once it's verified you will be able to access next steps.")
                if (result.data.app_data) {
                    setAppData(result.data.app_data);
                }
            }
            setLoading(false);
            setOpenDialogue(false);
        }
        catch (e) {
            //already toasted
            setOpenDialogue(false);
            setLoading(false);
        }
    }

    const isPaymentSucceeded = payment && payment.status === PAYMENT_STATUS.SUCCESSFUL;
    const isPaymentProofUploaded = appData && appData.payment_proof_uploaded;
    const isStatusGreaterThanModule = status > APP_STATUS.PENDING_APPROVAL;

    return (
        <Container className={classes.container}>
            <UploadPaymentProofFileDialogue
                openDialogue={openDialogue}
                dialogueCloseHandler={() => { setOpenDialogue(false) }}
                successCB={addFilePathToApplicationData}
            />
            {
                isPaymentSucceeded ?
                    <Container className={classes.container} component={Paper} style={{padding: 50}}>
                        <Typography variant={"h4"} align={'center'}>Payment Details</Typography>
                        <br/><br/>
                        <Grid container spacing={3}>
                            <LabelValuePair label={'Payment Status'} value={'Completed'}/>
                            <LabelValuePair label={'Payment Amount'} value={`${payment.currency.toUpperCase()} ${payment.amount}`}/>
                            <LabelValuePair label={'Payment Time'} value={new Date(payment.updatedAt).toString()}/>
                            <LabelValuePair label={'Payment By'} value={payment.updatedBy}/>
                        </Grid>
                    </Container>
                :
                    <>
                        {
                            isPaymentProofUploaded ?
                                <Container className={classes.container}>
                                    <Typography className={classes.instruction} variant="body1" align={'left'}>  <InfoIcon />  Payment verification in progress. You will receive an email upon completion, allowing you to upload program materials for alignment into this portal.
                                       <br/><br/>
                                        <span style={{ marginLeft: '28px' }}>For additional uploads or revisions, please use the options below. (Accepted file formats: .jpg, .pdf, .png)</span>
                                    </Typography>
                                    <TextField id='assumption1' variant="outlined" label={'Payment Amount'} value={paymentAmount} style={{margin: '20px'}}
                                               InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true }}
                                    />

                                    <Box textAlign={'center'}>
                                        <Button className={classes.button} variant="contained" color="primary"
                                                style={{ marginLeft: '10px' }} href={appData.payment_proof_file_path} target="_blank" rel="noopener noreferrer">
                                            View Payment Proof
                                        </Button>
                                        {
                                            !isStatusGreaterThanModule &&
                                            <Button variant="contained" color="primary"
                                                    style={{marginLeft: '10px'}}
                                                    className={classes.button}
                                                    onClick={() => setOpenDialogue(true)}>
                                                Re-upload Payment Proof
                                            </Button>
                                        }
                                    </Box>
                                </Container>
                                :
                                <Container className={classes.container}>
                                    <Typography component="h1" variant="h5" color="inherit" style={{ fontWeight: 'bold' }}>
                                        Add Payment Proof
                                    </Typography>
                                    <Typography className={classes.instruction} variant="body1">  <InfoIcon />  Once you’ve made payment, please provide your payment receipt by uploading the receipt here. Once verified, you will receive an email and can log back into this portal to upload your program materials for alignment. (File formats accepted: .jpegs, .pdfs, .pngs)
                                    </Typography>
                                    <TextField id='assumption1' variant="outlined" label={'Payment Amount'} value={paymentAmount} style={{margin: '20px'}}
                                               InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true }}
                                    />
                                    <Grid container>
                                        <Grid item md={6} style={{textAlign:'center', paddingTop:'10px'}}>
                                            <Typography component="h1" variant="h5" color="inherit" style={{ fontWeight: 'bold' }}>
                                                Wire Transfer
                                            </Typography>
                                            <Button variant="contained" className={classes.button} style={{marginTop:'20px'}} onClick={() => setOpenDialogue(true)}>Upload Proof</Button>
                                        </Grid>
                                        <Divider className={classes.verticalDivider} />
                                        <Grid item md={5} style={{textAlign:'center', paddingTop:'10px'}}>
                                            {
                                                process.env.REACT_APP_ONLINE_PAYMENTS === "1" && paymentFetched && !isStatusGreaterThanModule &&
                                                <>
                                                    <Typography component="h1" variant="h5" color="inherit" style={{ fontWeight: 'bold' }}>
                                                        Online Payment
                                                    </Typography>
                                                    <br/>
                                                    <StripePayment {...props}/>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>

                                </Container>
                        }

                    </>
            }
            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
}

export default PaymentModule;
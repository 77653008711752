import React, {useEffect} from 'react';
import Bg17 from "../../../../assets/images/report/bg17.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {DQ_DEFINITIONS} from "../../../../utils/constants";
import {asciiSortCompareFunWithKey, getMapFromObjectListByKey} from "../../../../utils/utils";
import {makeStyles} from "@material-ui/core/styles";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        "& th,td": {
            border: "1px solid black",
            padding: "5px 10px",
            fontSize: "18px"
        },
        "& th": {
            padding: "18px 12px",
            textAlign: "center",
            backgroundColor: "deepskyblue",
            color: "white",
        },
        "& th:nth-child(1)": {
            width: "10%"
        },
        "& th:nth-child(2),": {
            width: "10%"
        },
        "& th:nth-child(3)": {
            width: "30%"
        },
        "& tr": {
            color: "black"
        },
        "& tr:nth-child(even)": {
            backgroundColor: "#f6bc8f"
        },
        "& td.bold": {
            fontWeight: "bold"
        },
    },
}));


function BadgeRow(props) {
    const {badge} = props;

    return <>
        {
            badge &&
            <tr>
                <td className={'bold'}>{badge.code}</td>
                <td className={'bold'}>{badge.title}</td>
                <td>{badge.definition}</td>
                {/*<td>{badge.questions.map((q) => <>{q}<br/></>)}</td>*/}
            </tr>
        }
    </>
}

export default function Page16a(props) {

    const {pageNumber, coverage, instruments, onlyTable = false} = props;

    const classes = tableStyles();
    const badges = [];

    if(coverage) {
        for(const item of coverage) {
            const {dqc_no} = item;
            const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
            const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
            const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
            const definition = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].definition: "";
            const questions = instruments.filter((i) => i.dqc_no === dqc_no).map((i, idx) => `${idx+1}. ${i.instrument_question}`)
            badges.push({code, title, definition, questions});
        }
    }

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg17}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} height={'inherit'} width={'inherit'} gridRowGap={20}>
            {
                <Box display={'flex'} flexDirection={'row'} flexBasis={'10%'} justifyContent={'flex-start'} height={'inherit'} width={'inherit'}>
                    <Box flexBasis={'22%'}/>
                    <Box>
                        <Typography variant={'h3'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 75}}>
                            Areas of Enhancement II:
                        </Typography>
                        <Typography variant={'h3'} component='span' style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>
                            {/*List of Instruments*/}
                            <br/>
                        </Typography>
                    </Box>
                </Box>
            }
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={"space-around"} height={"inherit"} width={'inherit'} px={5} py={2}>
                <Box flexBasis={'100%'} alignSelf={onlyTable ? "flex-start" : "inherit"}>
                    {console.log("Hello")}
                    <table className={classes.root} style={{width: "inherit"}}>
                        <tr>
                            <th>DQ#</th>
                            <th>Digital Competency</th>
                            <th>What Can Be Measured</th>
                            {/*<th>Sample Assessment Questions</th>*/}
                        </tr>
                        {
                            badges.map(b => <BadgeRow badge={b}/>)
                        }
                    </table>
                </Box>
            </Box>
        </Box>
    </ReportPage>
}
import React from 'react';
import Box from "@material-ui/core/Box";
import DQGSMRelationship from "../../../../assets/images/report/DQGSMRelationship.png";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import Bg5 from "../../../../assets/images/report/bg5.png";

const leftBoxStyle = {
    flexBasis: '20%',
    overflow: 'hidden', // Ensures the image doesn't overflow its container
    alignItems: 'center'
};
const imageStyle = {
    width: '90%', // Ensures the image takes 100% width of the container
    height: 'auto', // Maintains the image's aspect ratio
    display: 'block', // Removes any extra space below the image
    marginRight: '10px', // Add some margin to the right of the image
    alignSelf: 'middle'
};
export default function Page5(props) {

    const {pageNumber} = props;

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg5}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} gridRowGap={"8%"} px={10}>
            <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 70}}>
                About:<Typography variant={'h4'} component='span' style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}> DQ Micro-Badges for Digital Skills</Typography>
            </Typography>
                <Box display={'flex'}>
                    <Box style={leftBoxStyle}><img src={DQGSMRelationship} alt="Left Box" style={imageStyle} /></Box>
                    <Box flexBasis="80%">
                        <Typography style={{color: 'grey', fontWeight: "lighter", fontSize: "18px"}}>
                            <p>The DQ Global Standards Micro-credentials (GSM) system is a list of the micro-learning blocks of the DQ Global Standards (IEEE 3527.1&trade;). The DQ GSMs are developed in each of the 32 DQ competencies. The DQ GSMs help users to find out which digital skills topics, within the
                                DQ Global Standards, that they will acquire through your program, and understand how the program will enhance specific literacy, safety, job readiness or well-being.</p>
                            <p>The DQ Seal analyses reveals how your program covers specific learning objectives of DQ 32 competencies in terms of the depth and breadth through the DQ GSM scores.</p>
                            <p>When a DQ GSM has sufficient depth, it is considered that the program reasonably addresses the learning objectives of the corresponding GSM. We acknowledge that the program is aligned with a DQ competency when there is sufficient breadth of DQ GSMs in meeting the DQ competency standards.</p>
                            Through the DQ Seal analyses, you will be able to:<br/>
                            1. Identify areas of strength and/or areas for potential improvement of the programs,<br/>
                            2. Make a strategic decision on further enhancement of the programs,<br/>
                            3. Enhance the visibility of the programs as a best practice for specific GSMs.<br/>
                        </Typography>
                    </Box>
                </Box>
            </Box>
    </ReportPage>
}
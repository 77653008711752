export const TARGET_AGE = [
    {
        "value": "Below5",
        "label": "<5YO"
    },
    {
        "value": "5-7YO",
        "label": "5-7YO"
    },
    {
        "value": "8-9YO",
        "label": "8-9YO"
    },
    {
        "value": "10-12YO",
        "label": "10-12YO"
    },
    {
        "value": "13-14YO",
        "label": "13-14YO"
    },
    {
        "value": "15-18YO",
        "label": "15-18YO"
    },
    {
        "value": "19-24YO",
        "label": "19-24YO"
    },
    {
        "value": "25-34YO",
        "label": "25-34YO"
    },
    {
        "value": "35-44YO",
        "label": "35-44YO"
    },
    {
        "value": "45-54YO",
        "label": "45-54YO"
    },
    {
        "value": "55-64YO",
        "label": "55-64YO"
    },
    {
        "value": "Above65",
        "label": ">65YO"
    }
];

export const TARGET_AGE_MAP = {
    "Below5": {
        "label": "<5YO"
    },
    "5-7YO": {
        "label": "5-7YO"
    },
    "8-9YO": {
        "label": "8-9YO"
    },
    "10-12YO": {
        "label": "10-12YO"
    },
    "13-14YO": {
        "label": "13-14YO"
    },
    "15-18YO": {
        "label": "15-18YO"
    },
    "19-24YO": {
        "label": "19-24YO"
    },
    "25-34YO": {
        "label": "25-34YO"
    },
    "35-44YO": {
        "label": "35-44YO"
    },
    "45-54YO": {
        "label": "45-54YO"
    },
    "55-64YO": {
        "label": "55-64YO"
    },
    "Above65": {
        "label": ">65YO"
    }
};
import React from 'react';
import Bg19 from "../../../../assets/images/report/bg19.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";

export default function Page19(props) {

    const {pageNumber, onlyTable = false} = props;

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg19}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'inherit'} width={'inherit'} gridRowGap={10}>
            <Typography style={{fontSize: 28, color: "orangered", fontWeight: "lighter", textAlign: "center"}}>
                If you have any other questions, feel free to contact us:
            </Typography>
            <br/>
            <a href={'mailto:research@dqforall.com'} style={{fontSize: 28, color: "grey", fontWeight: "bold", textAlign: "center"}}>
                research@dqforall.com
            </a>
        </Box>
    </ReportPage>
}
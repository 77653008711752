import React from 'react';
import Bg16 from "../../../../assets/images/report/bg16.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";

export default function Page16(props) {

    const {pageNumber, onlyTable = false} = props;

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg16}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} height={'inherit'} width={'inherit'} gridRowGap={10}>
            {
                <Box display={'flex'} flexDirection={'row'} flexBasis={'10%'} justifyContent={'flex-start'} height={'inherit'} width={'inherit'}>
                    <Box flexBasis={'22%'}/>
                    <Box>
                        <Typography variant={'h3'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 75}}>
                            Areas of Enhancement II:
                        </Typography>
                        <Typography variant={'h3'} component='span' style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>
                            Impact Tracking
                        </Typography>
                    </Box>
                </Box>
            }
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={"space-around"} height={"inherit"} width={'inherit'} gridColumnGap={20} px={10}>
                <Box flexBasis={'45%'}>
                    <Typography style={{fontSize: 28, color: "grey", fontWeight: "lighter", textAlign: "left"}}>
                        Are you interested in including individual
                        assessment and certification functions in
                        your programs?
                        <br/>
                        <br/>
                        Would you like to be part of the global
                        DQ Index and part of global impact
                        monitoring?
                    </Typography>
                </Box>
                <Box flexBasis={'55%'}/>
            </Box>
        </Box>
    </ReportPage>
}
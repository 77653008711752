export const SIZE_EMPLOYEE = [
    {
        "value": "Self-employed",
        "label": "Self-employed"
    },
    {
        "value": "1-10employees",
        "label": "1-10 employees"
    },
    {
        "value": "11-50employees",
        "label": "11-50 employees"
    },
    {
        "value": "51-200employees",
        "label": "51-200 employees"
    },
    {
        "value": "201-500employees",
        "label": "201-500 employees"
    },
    {
        "value": "501-1000employees",
        "label": "501-1,000 employees"
    },
    {
        "value": "1001-5000employees",
        "label": "1,001-5,000 employees"
    },
    {
        "value": "5001-10000employees",
        "label": "5,001-10,000 employees"
    },
    {
        "value": "morethan10001employees",
        "label": "10,001+ employees"
    }
];

export const SIZE_EMPLOYEE_MAP = {
    "Self-employed": {
        "label": "Self-employed"
    },
    "1-10employees": {
        "label": "1-10 employees"
    },
    "11-50employees": {
        "label": "11-50 employees"
    },
    "51-200employees": {
        "label": "51-200 employees"
    },
    "201-500employees": {
        "label": "201-500 employees"
    },
    "501-1000employees": {
        "label": "501-1,000 employees"
    },
    "1001-5000employees": {
        "label": "1,001-5,000 employees"
    },
    "5001-10000employees": {
        "label": "5,001-10,000 employees"
    },
    "morethan10001employees": {
        "label": "10,001+ employees"
    }
}
import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Button, makeStyles } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { getCompanyInfoService } from "../../services/company.service";
import { REGIONS_MAP } from "../../utils/META/cat_101";
import { ORG_TYPES_MAP } from "../../utils/META/cat_102";
import { SECTORS_MAP } from "../../utils/META/cat_103";
import { HIST_DURATIONS_MAP } from "../../utils/META/cat_104";
import { SIZE_EMPLOYEE_MAP } from "../../utils/META/cat_105";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import InfoIcon from '@material-ui/icons/Info'

const useStyles = makeStyles((theme) => ({
    label: {
        textAlign: 'right',
        marginRight: theme.spacing(2)
    },
    value: {
        textAlign: 'left',
        marginTop: theme.spacing(0.8),
        marginLeft: theme.spacing(2)
    },
    button: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 3, 1, 3),
        fontSize: '18px',
        backgroundColor: theme.palette.info.main,
    },
    instruction: {
        margin: theme.spacing(3,3,3,0),
    },
}));

const key_desc = {
    NameOrg: "Name of organization",
    NameContact: "Name of contact person",
    NameDept: "Department and position of contact person",
    Region: "Country of your organization’s headquarters",
    OrgType: "Type of organization",
    Sector: "Sector of your organization",
    // SectorEntered: "Type your Sector",
    History: "Time your organization has been in this industry",
    SizeEmployee: "Number of employees in your organization",
    OrgVision: "Organization's vision and mission"
}

const CompanyInfoView = () => {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [compInfo, setCompInfo] = useState({
        NameOrg: '',
        NameContact: '',
        NameDept: '',
        Region: '',
        OrgType: '',
        Sector: '',
        // SectorEntered: '',
        History: '',
        SizeEmployee: '',
        OrgVision: '',
    });

    const getCompanyInfo = async () => {
        try {
            setLoading(true);
            const result = await getCompanyInfoService();
            if (!result.error) {
                if (result.data.comp) {
                    const region_name = REGIONS_MAP[result.data.comp.region].name ?
                        REGIONS_MAP[result.data.comp.region].name : result.data.comp.region;

                    const org_type_label = ORG_TYPES_MAP[result.data.comp.org_type].label ?
                        ORG_TYPES_MAP[result.data.comp.org_type].label : result.data.comp.org_type;

                    const sector_label = SECTORS_MAP[result.data.comp.sector].label ?
                        SECTORS_MAP[result.data.comp.sector].label : result.data.comp.sector;

                    const history_label = HIST_DURATIONS_MAP[result.data.comp.history].label ?
                        HIST_DURATIONS_MAP[result.data.comp.history].label : result.data.comp.history;

                    const size_emp_label = SIZE_EMPLOYEE_MAP[result.data.comp.size_employee].label ?
                        SIZE_EMPLOYEE_MAP[result.data.comp.size_employee].label : result.data.comp.size_employee;

                    setCompInfo({
                        ...compInfo,
                        NameOrg: result.data.comp.name_org,
                        NameContact: result.data.comp.name_contact,
                        NameDept: result.data.comp.name_dept,
                        Region: region_name,
                        OrgType: org_type_label,
                        Sector: sector_label === 'Other' ? result.data.comp.sector_entered : sector_label,
                        History: history_label,
                        SizeEmployee: size_emp_label,
                        OrgVision: result.data.comp.org_vision,
                    });
                }
                setLoading(false);
            }
        }
        catch (e) {
            // already toasted the error
            setLoading(false);
        }
    }

    useEffect(() => {
        getCompanyInfo().then((res) => `fetch initiated, ${res}`);
    }, [])

    const handleUpdateClick = () => {
        history.push('/company/update');
    };

    return (
        <Container>
            <Grid container spacing={1}>
                <Typography  className={classes.instruction} variant="body1">  <InfoIcon/>  In order to start the application process, please update your company information below. This will help us to identify who the program belongs to.
                </Typography>
            </Grid>
            <Grid container spacing={3}>
                {Object.entries(compInfo).map(([key, value]) => (
                    <Grid item xs={12} key={key}>
                        <Grid container justify="center" alignItems="flex-start">
                            <Grid item xs={6}>
                                <Typography variant="h6" className={classes.label}>{key_desc[key]}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" className={classes.value}>{value}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Grid container justifyContent="center" style={{ marginBottom: '20px', marginTop: '50px' }}>
                <Button className={classes.button} variant="contained" color="primary" onClick={handleUpdateClick}>
                    Edit Info
                </Button>
            </Grid>
            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
};

export default CompanyInfoView;

import React from 'react';
import Bg5 from "../../../../assets/images/report/bg5.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {makeStyles} from "@material-ui/core/styles";
import {DQ_DEFINITIONS} from "../../../../utils/constants";
import {asciiSortCompareFunWithKey} from "../../../../utils/utils";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        "& th,td": {
            border: "1px solid black",
            padding: "5px 10px",
            fontSize: "18px"
        },
        "& th": {
            padding: "18px 12px",
            textAlign: "center",
            backgroundColor: "deepskyblue",
            color: "white",
        },
        "& th:nth-child(1)": {
            width: "10%"
        },
        "& th:nth-child(2),": {
            width: "70%"
        },
        "& th:nth-child(3)": {
            width: "20%"
        }
    },
}));

const divDQStyles = {
    backgroundColor: '#f6bc8f',
    padding: '4px 8px',
    borderRadius: '2px',
    border: '1.5px solid black',
    display: 'inline-block',
    whiteSpace: 'nowrap', // Prevent text from wrapping
    textAlign: 'right',
    marginBottom: '5px',
    width: '100%',
    marginRight: '-5px',
};

const divGSMStyles = {
    backgroundColor: 'white',
    padding: '1px 15px',
    borderRadius: '8px',
    border: '1.5px solid black', // You can adjust the color as needed
    display: 'inline-block',
    alignSelf: 'right',
    textAlign: 'right',
    marginTop: '5px',
    marginBottom: '5px',
    marginRight: '-5px',
};
function MicroBadgeRow(props) {
    const {badge, micro_badges} = props;

    const badge_style = {backgroundColor: "#f6bc8f", fontWeight: "bold", color: "black"}
    const micro_style = {fontWeight: "normal", maxWidth: 500}
    const center_text_style = {textAlign: "center"}

    return <>
        {
            badge &&
            <tr>
                <td style={{...badge_style, ...center_text_style}}>{badge.code}</td>
                <td style={badge_style}>{badge.title}</td>
                <td style={{...badge_style, ...center_text_style}}>{badge.count}</td>
            </tr>
        }
        {
            micro_badges.map((mic, index) => <tr>
                {!index && <td style={micro_style} rowSpan={micro_badges.length}/>}
                <td style={micro_style}>{mic.title}</td>
                <td style={{...micro_style, ...center_text_style}}>{mic.count}</td>
            </tr>)
        }
    </>
}

export default function Page9(props) {

    const {pageNumber, depth, coverageMap, onlyTable = false} = props;

    const classes = tableStyles();

    const badges = {};
    if(depth.data) {
        for(const item of depth.data) {
            const {dqc_no, mb_no, mb_name, level9_count, is_first_mb} = item;
            const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
            const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
            const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
            if(!badges[dqc_no]) badges[dqc_no] = {micro_badges: []}
            if(is_first_mb) badges[dqc_no].badge = {code, title, count: coverageMap[dqc_no].count}
            badges[dqc_no].micro_badges.push({title: `${mb_no}: ${mb_name}`, count: level9_count})
            badges[dqc_no].micro_badges.sort(asciiSortCompareFunWithKey('title'));
        }
    }

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg5}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} height={'inherit'} width={'inherit'} gridRowGap={10}>
            {
                <Box flexBasis={'10%'} px={10}>
                    <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 70}}>
                        DQ Seal Analysis II: <Typography variant={'h4'} component='span' style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>
                        The program is aligned with the following DQ Micro-Badges based on the depth of the Learning Messages
                        </Typography>
                    </Typography>
                </Box>
            }

            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justify={'flex-end'} justifyContent={"flex-end"} height={"inherit"} width={'inherit'} gridColumnGap={15}>
                <Box flexBasis={'10%'} pl={10}>
                    {
                        !onlyTable && <div align={'right'}>
                            <div id={'dq-legend'} style={divDQStyles}>DQ Competency</div>
                            <div id={'gsm_legend'} style={divGSMStyles}>GSM</div>
                            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                        </div>
                    }
                    {
                        onlyTable && <div align={'right'}>
                            <div style={{color:'white'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </div>
                    }
                </Box>
                <Box flexBasis={'60%'} pl={1} alignSelf={onlyTable ? "flex-start" : "inherit"}>
                    <table className={classes.root} style={{width: "inherit"}}>
                        <tr>
                            <th>DQ#</th>
                            <th>Digital Competency</th>
                            <th># of Messages</th>
                        </tr>
                        {
                            Object.entries(badges).map(([_, b]) => <MicroBadgeRow badge={b.badge} micro_badges={b.micro_badges}/>)
                        }
                    </table>
                </Box>
                <Box flexBasis={'40%'}>
                    {
                        !onlyTable && <div>
                        <Box style={{backgroundColor: "#444", borderTopLeftRadius: "20px", padding: 20}}>
                            <Typography style={{fontSize: 24, color: "white", fontWeight: "lighter", fontStyle: "italic"}}>
                                *Depth of learning messages refers to the extent of content coverage within each DQ GSM. This analysis evaluates the extent to which your program content covers specific learning  topics within each DQ GSM.
                            </Typography>
                        </Box>
                            <Box style={{backgroundColor: "#0000", borderTopLeftRadius: "0px", padding: 20}}>
                                <Typography style={{fontSize: 24, color: "black", fontWeight: "lighter"}}>
                                    By earning DQ GSMs, we acknowledge the content adequately addresses the learning objectives related to the corresponding points.
                                </Typography>
                            </Box>
                        </div>
                    }
                </Box>
            </Box>
        </Box>
    </ReportPage>
}
import React from 'react';
import Bg5 from "../../../../assets/images/report/bg5.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";

export default function Page17(props) {

    const {pageNumber, onlyTable = false} = props;

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg5}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} height={'inherit'} width={'inherit'} gridRowGap={100}>
            {
                !onlyTable &&
                <Box flexBasis={'10%'} px={10}>
                    <Typography variant={'h3'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 70}}>
                        About Our Organization
                    </Typography>
                </Box>
            }
            <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={"space-around"} height={"inherit"} width={'inherit'}>
                <Box flexBasis={'40%'}>
                    <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, textAlign: "center"}}>
                        About DQ Institute
                    </Typography>
                    <br/>
                    <br/>
                    <Typography style={{fontSize: 18, color: "grey", fontWeight: "lighter", textAlign: "justify"}}>
                        The DQ Institute is an international think-tank that is
                        dedicated to setting global standards for digital
                        intelligence that ensures safety, empowerment, and well-being of individuals, organizations, and nations in the
                        digital age. It is registered as a 501(c)(3) Not-For-Profit
                        Organization in the United States (Employer ID #82-
                        0628471) and a public company limited by guarantee in
                        Singapore (UEN 202142503D). Its affiliated organizations
                        are DQ Lab Pte Ltd, a social enterprise based in Singapore
                        that focuses on research, development, and
                        dissemination of the DQ Global Standards (IEEE 3527.1TM)
                        and the Coalition for Digital Intelligence.
                    </Typography>
                </Box>
                <Box flexBasis={'40%'}>
                    <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, textAlign: "center"}}>
                        About DQ Lab
                    </Typography>
                    <br/>
                    <br/>
                    <Typography style={{fontSize: 18, color: "grey", fontWeight: "lighter", textAlign: "justify"}}>
                        DQ Lab Pte Ltd is a global social enterprise arm of the DQ
                        Institute. It is dedicated to provide innovative products
                        and services to help assess, educate, and empower
                        individuals, organizations, and nations to be digitally safe
                        and competent.
                    </Typography>
                </Box>
            </Box>
        </Box>
    </ReportPage>
}
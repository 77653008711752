import {invokeApi} from '../bl_libs/invokeApi';
import {LOCAL_STORAGE_KEYS} from "../utils/constants";

export const loginUser = (email, password, remember_me) => {
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/login',
        method : 'POST'
    };
    requestObj['postData'] = {
        email,
        password,
        remember_me
    };

    return invokeApi(requestObj);
}

export const registerUser = (email, password, remember_me) => {
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/register',
        method : 'POST'
    };
    requestObj['postData'] = {
        email,
        password,
        remember_me
    };

    return invokeApi(requestObj);
}

export const logoutUser = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/logout',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        all_devices: true
    };

    return invokeApi(requestObj);
};

export const initResetPassword = (email) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/init/reset/password',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        email
    };

    return invokeApi(requestObj);
}

export const resetPassword = (reset_code, password) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/password/reset',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        reset_code,
        password
    };

    return invokeApi(requestObj);
}

export const changePassword = (old_password, new_password) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/password/change',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        old_password,
        new_password
    };

    return invokeApi(requestObj);
};

export const resendEmailConfirmation = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/email/resend/confirm_token',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj);
};

export const confirmEmail = (confirmation_token) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/email/confirm',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        confirmation_token
    };

    return invokeApi(requestObj);
}

export const refreshToken = () => {
    const auth_token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    const refresh_token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.refresh_token));
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/refresh/token',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${auth_token}`,
            'Refresh-Token': refresh_token
        }
    };
    requestObj['postData'] = {
        auth_token,
        refresh_token
    };

    return invokeApi(requestObj);
};
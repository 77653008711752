import {store} from "react-notifications-component";
import {TOAST_TYPES} from "./constants";

export const toast = (type, title, message, duration = 10000) => {
    store.addNotification({
        title,
        message,
        type,
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration,
            showIcon: true
        }
    });
}

export const toastWarning = (message) => {
    toast(TOAST_TYPES.WARN, "Server Response!", message);
}

export const getMapFromObjectListByKey = (objectList, mapKey) => {
    let dict = {};
    for (let item of objectList) {
        dict[item[mapKey]] = item;
    }
    return dict;
}

export const getArrayFromMap = (map) => {
    return Object.keys(map).map((k) => map[k])

}

export const getPresenceMap = (simple_list) => {
    let dict = {};
    for (let item of simple_list) {
        dict[item] = true;
    }
    return dict;
}

export const asciiSortCompareFunWithKey = (key) => {
    return (a, b) => a[key].localeCompare(b[key]);
}

export function* chunks(arr, n) {
    for (let i = 0; i < arr.length; i += n) {
        yield arr.slice(i, i + n);
    }
}

export function* varChunks(arr, n) { //variable length chunks
    const n_array = [].concat(n);

    function getNextSize(array, i) {
        return array[i] ? array[i] : array[array.length - 1];
    }

    for (let i = 0, j = 0; i < arr.length; i += getNextSize(n_array, j), j += 1)
    {
        yield arr.slice(i, i + getNextSize(n_array, j));
    }
}

export const splitEveryNthPos = function (string, separator, position) {
    const split_array = string.split(separator)
    const split_array_len = split_array.length;
    const final_array = [];
    let buffer = "";
    for(let word_index in split_array){
        const word = split_array[word_index]
        buffer += (word);
        if(buffer.length >= position || parseInt(word_index) === split_array_len-1){ //if buffer has max length possible, or we have reached last index
            final_array.push(buffer)
            buffer = ""
        }
        else {
            buffer += separator
        }
    }
    return final_array;
}

String.prototype.format = function(placeholders) {
    let s = this;
    for(const propertyName in placeholders) {
        const re = new RegExp('{' + propertyName + '}', 'gm');
        s = s.replace(re, placeholders[propertyName]);
    }
    return s;
};

export const CURRENT_VERSION = '1.10';

export const TOAST_TYPES = {
    DANGER: 'danger',
    WARN: 'warning',
    SUCCESS: 'success',
    INFO: 'info'
};

export const LOCAL_STORAGE_KEYS = {
    auth_token: 'dq_seal_cl_auth_token',
    auth_user: 'dq_seal_cl_auth_user',
    refresh_token: 'dq_seal_cl_refresh_token'
}

export const DEFAULT_PASS_FOR_LICENSE = 'pass123';
export const DEFAULT_PASS_FOR_DASHBOARD = 'p@ssw0rd';

export const USER_TYPE = {
    REGULAR: 10,
    SUPER_USER: 1,
    ADMIN_PORTAL: 2,
    ORG_PORTAL: 3
};


export const USER_ACCESS_LEVEL = {
    NOT_APPLICABLE: 0,
    NO_ACCESS: 1,
    PARTIAL_ACCESS_ADMIN_PORTAL: 2,
    FULL_ACCESS_ADMIN_PORTAL: 3,
    FULL_ACCESS_ANALYTICS_PORTAL: 4,
    PARTIAL_ACCESS_ANALYTICS_PORTAL: 5,
};

export const USER_TYPE_VERBOSE = {
    10: 'REGULAR',
    1: 'SUPER_USER',
    2: 'ADMIN_PORTAL',
    3: 'ORG_PORTAL'
};

export const USER_ACCESS_LEVEL_VERBOSE = {
    0: 'N/A',
    1: 'No Access',
    2: 'Partial Access (Admin)',
    3: 'Full Access (Admin)',
    4: 'Full Access (Analytics - Full Org)',
    5: 'Partial Access (Analytics)',
    6: 'Full Access (Analytics - Single Inst)'
};

export const ACL_ENABLE_ENDPOINTS = []
export const URL_PERMS_MAP = {}


export const SEAL_TYPE = {
    ALIGN: 0,
    ENHANCE: 1,
    CERTIFY: 2
}

export const SEAL_TYPE_VERBOSE = {
    [SEAL_TYPE.ALIGN]: 'Aligned',
    [SEAL_TYPE.ENHANCE]: 'Enhanced',
    [SEAL_TYPE.CERTIFY]: 'Certified'
}

export const APP_STATUS = {
    DRAFT: 0,
    WITHDRAWN: 1,
    REJECTED: 2,
    PENDING_PAYMENT: 10,
    PENDING_APPROVAL: 20,
    IN_PROGRESS_COLLECT_DATA: 30,
    HALTED_COLLECT_DATA: 40,
    COLLECTED_DATA: 45,
    IN_PROGRESS_PROCESS_DATA: 50,
    HALTED_PROCESS_DATA: 60,
    PENDING_CLEANING: 65,
    IN_PROGRESS_LEVEL_MAPPING: 67,
    PENDING_REVIEW_MAPPING: 70,
    PENDING_ANALYSIS: 75,
    PENDING_REVIEW_REPORT: 80,
    HALTED_REPORT: 90,
    COMPLETED: 100,

    READY_TO_CERTIFY: 105,
    ADD_ASSESS_REQ_RECEIVED: 110,
    REQ_REVIEWED_ON_HOLD: 115,
    REQ_REVIEWED_REJECTED: 116,
    REQ_REVIEWED_PAYMENT_PENDING: 120,
    PAYMENT_RECEIVED_VER_PENDING: 130, // assessments & licenses cost.
    PAYMENT_DECLINE_ON_HOLD: 135,
    PAYMENT_DECLINE_REJECTED: 136,
    PAYMENT_CONFIRMED: 138,
    PENDING_AUTO_ASSESS_GENERATION: 140,
    AUTO_ASSESS_GENERATION_IN_PROGRESS: 145,
    AUTO_ASSESS_GENERATION_HALTED: 150,
    AUTO_ASSESS_GENERATION_COMPLETED: 160, // in-progress review assessments
    ASSESSMENTS_READY: 200,
}

export const APP_STATUS_VERBOSE = {
    [APP_STATUS.DRAFT]: 'In Review (Draft)',
    [APP_STATUS.WITHDRAWN]: 'Withdrawn',
    [APP_STATUS.REJECTED]: 'Rejected',
    [APP_STATUS.PENDING_PAYMENT]: 'Pending Payment',
    [APP_STATUS.PENDING_APPROVAL]: 'Pending Approval',
    [APP_STATUS.IN_PROGRESS_COLLECT_DATA]: 'In Progress (Collecting data)',
    [APP_STATUS.HALTED_COLLECT_DATA]: 'Halted',
    [APP_STATUS.COLLECTED_DATA]: 'In Progress (Processing data)',
    [APP_STATUS.IN_PROGRESS_PROCESS_DATA]: 'In Progress (Processing data)',
    [APP_STATUS.HALTED_PROCESS_DATA]: 'Halted',
    [APP_STATUS.PENDING_CLEANING]: 'In Progress (Analyzing)',
    [APP_STATUS.IN_PROGRESS_LEVEL_MAPPING]: 'In Progress (Analyzing)',
    [APP_STATUS.PENDING_REVIEW_MAPPING]: 'In Progress (Analyzing)',
    [APP_STATUS.PENDING_ANALYSIS]: 'In Progress (Analyzing)',
    [APP_STATUS.PENDING_REVIEW_REPORT]: 'In Progress (Analyzing)',
    [APP_STATUS.HALTED_REPORT]: 'Halted',
    [APP_STATUS.COMPLETED]: 'Report Available',

    [APP_STATUS.READY_TO_CERTIFY]: 'Ready to Certify',
    [APP_STATUS.ADD_ASSESS_REQ_RECEIVED]: 'Certify: In-Progress',
    [APP_STATUS.REQ_REVIEWED_ON_HOLD]: 'Certify: In-Progress',
    [APP_STATUS.REQ_REVIEWED_REJECTED]: 'Certify: In-Progress',
    [APP_STATUS.REQ_REVIEWED_PAYMENT_PENDING]: 'Certify: In-Progress',
    [APP_STATUS.PAYMENT_RECEIVED_VER_PENDING]: 'Certify: In-Progress',
    [APP_STATUS.PAYMENT_DECLINE_ON_HOLD]: 'Certify: In-Progress',
    [APP_STATUS.PAYMENT_DECLINE_REJECTED]: 'Certify: In-Progress',
    [APP_STATUS.PAYMENT_CONFIRMED]: 'Certify: In-Progress',
    [APP_STATUS.PENDING_AUTO_ASSESS_GENERATION]: 'Certify: In-Progress',
    [APP_STATUS.AUTO_ASSESS_GENERATION_IN_PROGRESS]: 'Certify: In-Progress',
    [APP_STATUS.AUTO_ASSESS_GENERATION_HALTED]: 'Certify: In-Progress',
    [APP_STATUS.AUTO_ASSESS_GENERATION_COMPLETED]: 'Certify: In-Progress',
    [APP_STATUS.ASSESSMENTS_READY]: 'Assessments Available',
}

export const ANALYSIS_TYPES = {
    LEVEL_9_COVERAGE: 'Level_9_Coverage',
    LEVEL_8_COVERAGE: 'Level_8_Coverage',
    LEVEL_7_COVERAGE: 'Level_7_Coverage',
    LEVEL_7_ALIGNMENT: 'Level_7_Alignment',
    LEVEL_6_DEPTH: 'Level_6_Depth',
    LEVEL_6_BREADTH: 'Level_6_Breadth',
    LEVEL_6_ENOUGH: 'Level_6_Enough',
    LEVEL_5_ANALYSIS: 'Level_5_Analysis',
}

export const PAYMENT_STATUS = {
    // PENDING: 0,
    INITIALIZED: 1,
    PROCESSING: 2,
    SUCCESSFUL: 3,
    FAILED: 4,
    REFUNDED: 5
}

export const DQ_DEFINITIONS = {
    "DQ1": {
        title: "Digital Citizen Identity",
        definition: "The ability to build and manage a healthy identity as a digital citizen with integrity.",
        color: "#5abae4"
    },
    "DQ2": {
        title: "Balanced Use of Technology",
        definition: "The ability to manage one's life both online and offline in a balanced way by exercising self-control to manage screen time, multitasking, and one's engagement with digital media and devices.",
        color: "#809bd0"
    },
    "DQ3": {
        title: "Behavioural Cyber-Risk Management",
        definition: "The ability to identify, mitigate, and manage cyber risks (e.g., cyberbullying, harassment, and stalking) that relate to personal online behaviors.",
        color: "#df67a0"
    },
    "DQ4": {
        title: "Personal Cyber Security Management",
        definition: "The ability to detect cyber threats (e.g., hacking, scams, and malware) against personal data and device, and to use suitable security strategies and protection tools.",
        color: "#d70f76"
    },
    "DQ5": {
        title: "Digital Empathy",
        definition: "The ability to be aware of, be sensitive to, and be supportive of one's own and other's feelings, needs and concerns online.",
        color: "#e58744"
    },
    "DQ6": {
        title: "Digital Footprint Management",
        definition: "The ability to understand the nature of digital footprints and their real-life consequences, to manage them responsibly, and to actively build a positive digital reputation.",
        color: "#f3c64c"
    },
    "DQ7": {
        title: "Media and Information Literacy",
        definition: "The ability to find, organize, analyze, and evaluate media and information with critical reasoning.",
        color: "#d2dc1b"
    },
    "DQ8": {
        title: "Privacy Management",
        definition: "The ability to handle with discretion all personal information shared online to protect one's and others' privacy.",
        color: "#e5dc00"
    },
    "DQ9": {
        title: "Digital Co-Creator Identity",
        definition: "The ability to identify and develop oneself as a co-creator of the digital ecosystem.",
        color: "#9ed3e9"
    },
    "DQ10": {
        title: "Healthy Use of Technology",
        definition: "The ability to understand the benefits and harms of technology on one's mental and physical health and to use technology use while prioritizing health and well-being.",
        color: "#8ea4d5"
    },
    "DQ11": {
        title: "Content Cyber-Risk Management",
        definition: "The ability to identify, mitigate, and manage content cyber risks online (e.g., harmful usergenerated content, racist/hateful content, image-based abuse).",
        color: "#e491ba"
    },
    "DQ12": {
        title: "Network Security Management",
        definition: "The ability to detect, avoid, and manage cyber-threats to cloud-based collaborative digital environments.",
        color: "#de2a92"
    },
    "DQ13": {
        title: "Self-Awareness and Management",
        definition: "The ability to recognize and manage how one's value system and digital competencies fits with one's digital environment.",
        color: "#ea9e65"
    },
    "DQ14": {
        title: "Online Communication and Collaboration",
        definition: "The ability to use technology effectively to communicate and collaborate collectively, including at a distance.",
        color: "#f5cf7a"
    },
    "DQ15": {
        title: "Content Creation and Computational Literacy",
        definition: "The ability to synthesise, create, and produce information, media, and technology in an innovative and creative manner.",
        color: "#dee45d"
    },
    "DQ16": {
        title: "Intellectual Property Rights Management",
        definition: "The ability to understand and manage intellectual property rights (e.g., copyrights, trademarks, and patents) when using and creating content and technology.",
        color: "#ede300"
    },
    "DQ17": {
        title: "Digital Changemaker Identity",
        definition: "The ability to identify and develop oneself as a competent changemaker in the digital economy.",
        color: "#b8e7f6"
    },
    "DQ18": {
        title: "Civic Use of Technology",
        definition: "The ability to engage in civic participation for the well-being and growth of local, national, and global communities using technology.",
        color: "#acbfe5"
    },
    "DQ19": {
        title: "Commercial and Community Cyber-Risk Management",
        definition: "The ability to understand, mitigate, and manage commercial or community cyber-risks online, which is an organisational attempt to exploit individuals financially and/or through ideological persuasion (e.g., embedded marketing, online propaganda, and gambling).",
        color: "#f8b0d8"
    },
    "DQ20": {
        title: "Organisational Cyber Security Management",
        definition: "The ability to recognise, plan, and implement organisational cyber security defences.",
        color: "#e83ea2"
    },
    "DQ21": {
        title: "Relationship Management",
        definition: "The ability to skilfully manage one's online relationships through collaboration, conflict management, and persuasion.",
        color: "#f5bb8e"
    },
    "DQ22": {
        title: "Public and Mass Communication",
        definition: "The ability to communicate with an online audience effectively to exchange messages, ideas, and opinions reflecting wider business or societal discourses.",
        color: "#fadba2"
    },
    "DQ23": {
        title: "Data and AI Literacy",
        definition: "The ability to generate, process, analyze, present meaningful information from data and develop, use, and apply artificial intelligence (AI) and related algorithmic tools and strategies in order to guide informed, optimized, and contextually relevant decision-making processes.",
        color: "#e0de84"
    },
    "DQ24": {
        title: "Participatory Rights Management",
        definition: "The ability to understand and exercise one's powers and right to online participation (e.g., their rights to personal data protection, freedom of expression, or to be forgotten).",
        color: "#f5e34b"
    },
    "DQ25": {
        title: "Digital User Identity",
        definition: "The ability to have their identity as a digital users - having basic understanding on internet and digital media.",
        color: "#5abae4"
    },
    "DQ26": {
        title: "Active Use of Technology",
        definition: "The ability to actively use various types of digital media and technology.",
        color: "#718cc8"
    },
    "DQ27": {
        title: "Content Cyber-Risk Management",
        definition: "Identifying, mitigating, and managing risky content (e.g., harmful user-generated content, racist/hateful content, image-based abuse).",
        color: "#d9448e"
    },
    "DQ28": {
        title: "Personal Device Security Management",
        definition: "Ability to use strategies efficiently to protect personal digital devices.",
        color: "#cb0f64"
    },
    "DQ29": {
        title: "Emotional Awareness",
        definition: "The ability to critically recognise, evaluate, and express one’s emotions in digital environment.",
        color: "#db6025"
    },
    "DQ30": {
        title: "Online Communication",
        definition: "The ability to use technology effectively to communicate with others online.",
        color: "#efb33c"
    },
    "DQ31": {
        title: "ICT Literacy",
        definition: "The ability to use efficiently the elementary functions of information and communication technologies (essentially word/image/data processing, Internet and e-mail).",
        color: "#c0d500"
    },
    "DQ32": {
        title: "Digital Inclusion Rights",
        definition: "The ability to understand their rights to have quality digital access across sectors, SES, and genders.",
        color: "#cec300"
    },
};

export const ASSESSMENT_REQ_TYPES = {
    PRE_SELECT_PRE_POST: 1,
    PRE_SELECT_POST_ONLY: 2,
    STANDARD_DCT: 3,
    STANDARD_DPT: 4,
    CUSTOM_PRE_POST: 5,
    CUSTOM_POST_ONLY: 6,
}
import React, {useEffect, useState} from 'react';
import { useAuth } from "../../context/auth";
import {makeStyles} from "@material-ui/core/styles";

import {Card, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {getOverviewDataService} from "../../services/app.service";
import {APP_STATUS} from "../../utils/constants";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const useStyles = makeStyles((theme)=>({
    container: {
        padding: 20
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: "bold"
    }
}));

function StatusCard(props) {

    const {title, description} = props;
    const classes = useStyles();

    return <Card>
        <CardContent>
            <Typography variant={"h5"} align={'center'}>
                {title}
            </Typography>
            <br/>
            <Typography className={classes.title} variant={"h4"} align={'center'}>
                {description}
            </Typography>
        </CardContent>
    </Card>
}
function Home(){

    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const [statsData, setStatsData] = useState({
        total_apps: 0,
        pending_payment: 0,
        in_progress: 0,
        completed: 0,
    });

    async function fetchOverviewData() {
        try {
            setLoading(true);
            const result = await getOverviewDataService();
            console.log('result: ', result);
            if(!result.error) {
                let total_apps = 0;
                let pending_payment = 0;
                let in_progress = 0;
                let completed = 0;
                if (result.data.total_apps) {
                    total_apps = result.data.total_apps;
                }
                if (result.data.status_map) {
                    for (let item of result.data.status_map) {
                        switch (item._id) {
                            case APP_STATUS.DRAFT:
                            case APP_STATUS.PENDING_PAYMENT:
                                pending_payment += item.countC;
                                break;
                            case APP_STATUS.PENDING_APPROVAL:
                            case APP_STATUS.IN_PROGRESS_COLLECT_DATA:
                            case APP_STATUS.HALTED_COLLECT_DATA:
                            case APP_STATUS.IN_PROGRESS_PROCESS_DATA:
                            case APP_STATUS.HALTED_PROCESS_DATA:
                            case APP_STATUS.PENDING_REVIEW_MAPPING:
                            case APP_STATUS.PENDING_ANALYSIS:
                            case APP_STATUS.PENDING_REVIEW_REPORT:
                            case APP_STATUS.HALTED_REPORT:
                                in_progress += item.countC;
                                break;
                            case APP_STATUS.COMPLETED:
                                completed += item.countC;
                                break;
                        }
                    }

                }
                setStatsData({
                    total_apps,
                    pending_payment,
                    in_progress,
                    completed,
                })
                setLoading(false);
            }
        }
        catch (e) {
            // already toasted the error
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchOverviewData().then((res) => `fetch initiated, ${res}`);
    }, [])

    return <Grid className={classes.container} container spacing={5} justifyContent={'center'}>
        <Grid item xs={6}>
            <StatusCard title={'Total Applications'} description={statsData.total_apps ? ''+statsData.total_apps: '-'}/>
        </Grid>
        <Grid item xs={6}>
            <StatusCard title={'Pending Payment'} description={statsData.pending_payment ? ''+statsData.pending_payment: '-'}/>
        </Grid>
        <Grid item xs={6}>
            <StatusCard title={'In-Progress'} description={statsData.in_progress ? ''+statsData.in_progress: '-'}/>
        </Grid>
        <Grid item xs={6}>
            <StatusCard title={'Reports Available'} description={statsData.completed ? ''+statsData.completed: '-'}/>
        </Grid>
        <LoaderWithBackDrop loading={loading} />
    </Grid>
}

export default Home;
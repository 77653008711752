import React from 'react';
import Bg18 from "../../../../assets/images/report/bg18.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";

export default function Page18(props) {

    const {pageNumber, onlyTable = false} = props;

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg18}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'flex-start'} height={'inherit'} width={'inherit'}>
            {
                !onlyTable &&
                <Box flexBasis={'10%'} px={10}>
                    <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 70}}>
                        Using the DQ Brand
                    </Typography>
                </Box>
            }
            <Box flexBasis={'47%'} display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={"space-around"} height={"inherit"} width={'inherit'} px={3}>
                <Box flexBasis={'40%'}>
                    <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, textAlign: "center"}}>
                        THE DQ SEAL Logo
                    </Typography>
                    <br/>
                    <Typography style={{fontSize: 18, color: "grey", fontWeight: "lighter", textAlign: "justify"}}>
                       You can now use your DQ Aligned Seal on your products and
                           services to showcase that your program is aligned.<br/><br/>
                        You can find the logo graphic here:
                        <br/>
                        <a href={'https://storage.googleapis.com/dqlab-public/assets/DQ-Aligned.png'} target={"_blank"} style={{textDecoration: "underline", color: "deepskyblue"}}>THE DQ SEAL LOGO</a>
                        <br/><br/>
                        When placing the DQ Seal on a website, we suggest putting it
                        as a button that links back to the following
                        page: <a href={'www.dqlab.net'} style={{textDecoration: "underline", color: "deepskyblue"}}>www.dqlab.net</a> so that
                        they can connect and understand the meaning of it.
                    </Typography>
                </Box>
                <Box flexBasis={'40%'}>
                    <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, textAlign: "center"}}>
                        “POWERED BY DQ” logo
                    </Typography>
                    <br/>
                    <Typography style={{fontSize: 18, color: "grey", fontWeight: "lighter", textAlign: "justify"}}>
                        You can place the Powered by DQ logo together with your
                            product title or website as well.<br/><br/>
                        You can find the logo graphic here:
                        <br/>
                        <a href={'https://storage.googleapis.com/dqlab-public/assets/PoweredByDQ.png'} target={"_blank"} style={{textDecoration: "underline", color: "deepskyblue"}}>POWERED BY DQ LOGO</a>
                        <br/><br/>
                        When placing the “Powered by DQ” logo on a website, we suggest putting it
                        as a button that links back to the following
                        page: <a href={'www.dqlab.net'} style={{textDecoration: "underline", color: "deepskyblue"}}>www.dqlab.net</a> so that
                        they can connect and understand the meaning of it.
                    </Typography>
                </Box>
            </Box>
        </Box>
    </ReportPage>
}
import React from 'react';
import Bg4 from "../../../../assets/images/report/bg4.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";

export default function Page4(props) {

    const {pageNumber} = props;

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg4}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} px={10}>
            <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 70}}>
                About:<Typography variant={'h4'} component='span' style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}> DQ Framework 2.0 - 32 DQ Competencies</Typography>
            </Typography>
        </Box>
    </ReportPage>
}
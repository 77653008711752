import React from 'react';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {Grid, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
    },
    label: {
        textAlign: 'right',
        marginRight: theme.spacing(2),
        wordBreak: "break-word"
    },
    value: {
        textAlign: 'left',
        marginTop: theme.spacing(0.8),
        marginLeft: theme.spacing(2),
        wordBreak: "break-word"
    },
}));

const key_desc = {
    Success: "Definition of success for program",
    SuccessMeasure: "Measurement of success for program",
    KPITrack: "Process to ensure that the program’s objective has been met",
    RefLink: "Weblink that best describes your program",
    RefFramework: "Other references related to program"
}

function ProgramImpact(props) {

    const classes = useStyles();

    const {progImpact} = props;

    return <Container maxWidth={false} className={classes.container} component={Paper}>
        <Grid container spacing={3}>
            {Object.entries(key_desc).map(([key, value]) => (
                <Grid item xs={12} key={key}>
                    <Grid container justify="center" alignItems="flex-start">
                        <Grid item xs={6}>
                            <Typography variant="h6" className={classes.label}>{value}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" className={classes.value}>{progImpact[key] || ""}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    </Container>
}

export default ProgramImpact;

import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import {
Card, CardContent, Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import {Copyright} from '../../../components';
import { useFormik } from 'formik';
import {loginUser} from '../../../services/auth.service';
import { useAuth } from "../../../context/auth";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import PoweredbyDQ from "../../../assets/images/PoweredByDQ_white.png"
import DQLabLogo from "../../../assets/images/DQLAB_logo.png"
import RegisterIcon from "../../../assets/images/register.png"
import MappingIcon from "../../../assets/images/contentMapping.png"
import ReportIcon from "../../../assets/images/report.png"
import CertifiedIcon from "../../../assets/images/certified.png"
import VisualizeIcon from "../../../assets/images/visulaize-impacts.png"
import {
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import {Power} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    paper: {
        background: theme.palette.background.default,
        color: theme.palette.loginPage.text,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:'center',
        textAlign:'center',
        marginTop:theme.spacing(8),
        fontFamily:'Surt',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '90%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        color:theme.palette.background.default,
        fontFamily:'Surt',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: theme.palette.loginPage.text,
        color:  theme.palette.background.default,
        width:'40%',
        '&:hover':{
            background: theme.palette.loginPage.text,
            color: theme.palette.background.default,
        }
    },
    section1: {
        height:'70vh',
        fontFamily:'Surt',
    },
    section2: {
        background: theme.palette.loginPage.background,
        paddingBottom:'40px',
        paddingTop:'40px',
        color:  theme.palette.loginPage.text,
        textAlign:'center',
        width:'100vw',
        alignItems: 'center',
        display:'flex',
        justifyContent:'center',
        fontFamily:'Surt',
    },
    section3:{
            background:theme.palette.background.default,
            color:  theme.palette.loginPage.text,
            textAlign:'center',
            alignItems: 'center',
            display:'flex',
            fontFamily:'Surt',
            flexDirection:'column',
            paddingBottom:'30px',
            paddingTop:'40px',
        },
    formBackground: {
        background: theme.palette.loginPage.text,
        padding: '20px',
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap:'wrap',
        fontFamily:'Surt',
    },
    card: {
        display: 'block',
        justifyContent: 'center',
        backgroundColor:'#D2ECEC',
        height: '70vh',
        margin:'8px',
        borderRadius:'20px',
        paddingTop:'14%',
    },
    accordionContainer:{
        width:'85%',
        maxWidth:'100vw',
        padding:'30px',
        marginTop:'25px',
        textAlign:'left'
    },
    accordionTab: {
        background: 'transparent',
        color: theme.palette.loginPage.text,
    },
}));

const Login = (props) => {
    // const [isLoggedIn, setLoggedIn] = useState(false);
    const classes = useStyles();

    const { setAuthToken, setAuthUser } = useAuth();
    const referer = props.location.referer || '/home';
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember_me: false
        },
        onSubmit: async (values) => {
            console.log('Form data => ', values);
            try {
                setLoading(true);
                const result = await loginUser(values.email, values.password, values.remember_me);
                console.log('result.data -> ', result.data);
                if (result.data && result.data.user) {
                    await setAuthToken(result.data.auth_token);
                    await setAuthUser(result.data.user);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.refresh_token, JSON.stringify(result.data.refresh_token));
                    return <Redirect to={referer} />;
                    // setLoggedIn(true);
                }
                setLoading(false);
            } catch (e) {
                // already toasted the error
                setLoading(false);
            }
        }
    });

    // if (isLoggedIn) {
    //     return <Redirect to={referer} />;
    // }
    const isMobile = window.innerWidth < 768;
    return (
        <>
            <div>
                <Grid container className={classes.section1}>
                    <Grid item xs={12} md={7}  style={{backgroundColor:'#4EA5A7',paddingRight:'5%',paddingTop:'7%'}}>
                        <Typography variant="h3" style={{marginLeft:'21%',fontWeight:'bold', fontFamily:'Surt',color:'#023F5F'}}>
                            Get
                        </Typography>
                        <img src={PoweredbyDQ} alt="Image"
                             style={{ width:'80%', marginLeft:'15%',marginTop:'-7%' }} />
                    </Grid>

                    <Grid item xs={12} md={5}  mt={6}>
                        <Container component="main" maxWidth="sm">
                            <a href="https://dqlab.net/" target="_blank">
                            <img src={DQLabLogo} alt="Image"
                                 style={{ width:'8%',position:'absolute', top:10 ,right:35,cursor:'pointer'}} />
                            </a>
                            {/*<CssBaseline />*/}
                            <div className={classes.paper} style={{
                                paddingTop: isMobile ? '10px' : '10%',
                            }}>
                            <Typography variant="h2" style={{fontFamily:'Surt'}}>
                                    Sign in
                                </Typography>
                                <form className={classes.form} noValidate onSubmit={formik.handleSubmit} style={{fontFamily:'Surt'}}>
                                    <TextField
                                        style={{ background: '#ffff'}}
                                        variant="standard"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />
                                    <TextField
                                        style={{ background: '#ffff'}}
                                        variant="standard"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                    />
                                    <Grid container >
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="remember_me"
                                            id="remember_me"
                                            value={formik.values.remember_me}
                                            onChange={formik.handleChange}
                                        />}
                                        label="Remember me"
                                        style={{color:'#023F5F',fontFamily:'Surt'}}
                                    />
                                        <Grid item xs style={{textAlign:'right', paddingTop:'2%',fontFamily:'Surt',color:'#023F5F'}}>
                                            <Link href="/account/recover" variant="body2" >
                                                Forgot password?
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Typography align='center'>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Sign In
                                    </Button>
                                    </Typography>

                                        <Grid item style={{textAlign:'center',fontFamily:'Surt',color:'#023F5F'}}>
                                            <Link href="/register" variant="body2" >
                                                {"Don't have an account? Sign Up"}
                                            </Link>
                                        </Grid>

                                </form>
                            </div>

                            <LoaderWithBackDrop loading={loading} />
                        </Container>
                    </Grid>
                </Grid>

                <Grid container className={classes.section2}>
                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom style={{fontWeight:'bold', fontFamily:'Surt',color:'#023F5F'}}>
                            How it Works
                        </Typography>
                        <Typography variant="h5" gutterBottom style={{padding:'20px',fontFamily:'Surt'}}>
                            The following is a typical process of getting Powered by DQ. Depending on your needs, <br />the process can take from around 1-4 weeks.
                        </Typography>
                    </Grid>
                    <Grid container className={classes.cardContainer} >
                        <Grid item xs={10} md={2} >
                         <Card className={classes.card} >
                            <CardContent>
                                <Grid item xs={12} md={12}>
                                    <img src={RegisterIcon} alt="Image"
                                         style={{width:'33%'}}/>
                                </Grid>
                                <Typography variant="h4" gutterBottom style={{paddingTop:'11%',fontFamily:'Surt'}}>
                                    Step 1:
                                </Typography>
                                <Typography variant="h5" gutterBottom style={{ fontFamily:'Surt'}}>
                                    Register and Pay
                                </Typography>
                                <Typography variant="p" style={{position:'relative', top:'4.1em', fontStyle:'italic', fontSize:'19px',fontFamily:'Surt'}}>
                                    Sign up and make payment to start the application process.
                                </Typography>
                            </CardContent>
                        </Card>
                        </Grid>
                        <Grid item xs={10} md={2} >
                        <Card className={classes.card} >
                            <CardContent >
                                <Grid item xs={12} md={12}>
                                    <img src={MappingIcon} alt="Image"
                                         style={{width:'35%'}}/>
                                </Grid>
                                <Typography variant="h4" gutterBottom style={{marginTop:'8%',fontFamily:'Surt'}}>
                                    Step 2:
                                </Typography>
                                <Typography variant="h5" gutterBottom style={{ fontFamily:'Surt'}}>
                                    Map Your Content to the IEEE DQ Global Standards
                                </Typography>
                                <Typography variant="p" style={{position:'relative', top:'0.8em', fontStyle:'italic', fontSize:'19px',fontFamily:'Surt'}}>
                                    Share your program information and content to be mapped and aligned.
                                </Typography>
                            </CardContent>
                        </Card>
                        </Grid>
                        <Grid item xs={10} md={2}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Grid item xs={12} md={12}>
                                    <img src={ReportIcon} alt="Image"
                                         style={{width:'30%'}}/>
                                </Grid>
                                <Typography variant="h4" gutterBottom style={{marginTop:'12%', fontFamily:'Surt'}}>
                                    Step 3:
                                </Typography>
                                <Typography variant="h5" gutterBottom style={{ fontFamily:'Surt'}}>
                                    Get a DQ Seal Report
                                </Typography>
                                <Typography variant="p" style={{position:'relative', top:'2.5em', fontStyle:'italic', fontSize:'19px',fontFamily:'Surt'}}>
                                    Get a report showcasing what DQ competencies your program aligns with and what areas can be enhanced.
                                </Typography>
                            </CardContent>
                        </Card>
                        </Grid>
                        <Grid item xs={10} md={2}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Grid item xs={12} md={12}>
                                    <img src={CertifiedIcon} alt="Image"
                                         style={{width:'30%'}}/>
                                </Grid>
                                <Typography variant="h4" gutterBottom style={{marginTop:'7%',fontFamily:'Surt'}}>
                                    Step 4:
                                </Typography>
                                <Typography variant="h5" gutterBottom style={{ fontFamily:'Surt'}}>
                                    Add a Custom Assessment to get Certified
                                </Typography>
                                <Typography variant="p" style={{position:'relative', top:'0.8em', fontStyle:'italic', fontSize:'19px',fontFamily:'Surt'}}>
                                    Get a custom assessment based on your program’s content alignment for your users to test their skills before and after going through your program.
                                </Typography>
                            </CardContent>
                        </Card>
                        </Grid>
                        <Grid item xs={10} md={2}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Grid item xs={12} md={12}>
                                    <img src={VisualizeIcon} alt="Image"
                                         style={{width:'40%'}}/>
                                </Grid>
                                <Typography variant="h4" gutterBottom style={{marginTop:'10%',fontFamily:'Surt'}}>
                                    Step 5:
                                </Typography>
                                <Typography variant="h5" gutterBottom style={{ fontFamily:'Surt'}}>
                                    Visualize Your Program Impact
                                </Typography>
                                <Typography variant="p" style={{position:'relative', top:'2.4em', fontStyle:'italic', fontSize:'19px',fontFamily:'Surt'}}>
                                    Get a custom dashboard to see the efficacy and impact of your program by gender, school, class, age, and more!
                                </Typography>
                            </CardContent>
                        </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.section3}>
                    <Grid item md={12} xs={9}>
                    <Typography variant="h3" gutterBottom style={{fontFamily:'SurtBold'}}>
                        Frequently Asked Questions
                    </Typography>
                    </Grid>
                    <div className={classes.accordionContainer}>
                        <Accordion className={classes.accordionTab} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold',fontFamily:'Surt'}}>How do I start getting Powered by DQ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            Decide if you want to be DQ Aligned, DQ Enhanced, or DQ Certified. Once you’ve decided sign up, and submit your application. Our team will reach to you on next steps and payment invoice to be completed.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold',fontFamily:'Surt'}}>How long does it take to get Powered by DQ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            DQ Aligned can take as little as 3 days if you have everything ready.
                                        </li>
                                        <li>
                                            DQ Enhanced and DQ Certified can take anywhere from a week or longer depending on the complexity of the program. Normally, it does not take longer than a month to get DQ Certified.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold',fontFamily:'Surt'}}>How much does it cost?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            DQ Aligned costs $999 USD for a typical program/course that is under 1 hour to complete. Programs over an hour will cost an extra $500 USD per hour of content.
                                        </li>
                                        <li>
                                            DQ Enhanced depends on the amount of content needed and size of your program.
                                        </li>
                                        <li>
                                            DQ Certified depends how many users you intend to have assessments with, as we charge per user that will be using your Powered by DQ assessment.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold', fontFamily:'Surt'}}>How do I make payment? What are the payment options?</Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            You can make payment via credit card or work with our finance team to pay via international wire transfers.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold', fontFamily:'Surt'}}>Is Powered by DQ available in other languages? Can I upload content in other languages?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            Currently, our reports and communications are only in English.
                                        </li>
                                        <li>
                                            All program content shared into our platform should be translated into English as our system currently cannot map other languages at this time.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold', fontFamily:'Surt'}}>What if I want a custom assessment?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            Our team will reach out to you after you have received your DQ SEAL Report to work with you on your custom needs.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold', fontFamily:'Surt'}}>If I build an assessment, how do my users access it?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            Our team provides flexibility for program providers to either 1) access your newly built assessment on our mobile-friendly website or 2) integrate an API version to be connected with your platform. We also provide technical support if needed as well.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold',fontFamily:'Surt'}}>What if my program is not digital skills related?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            Powered by DQ focuses primarily on digital skills education programs with emphasis on digital citizenship, digital literacy, digital wellbeing, digital readiness, digital skills, and technology. We do not align programs like history, science, arts, math, physical education, etc. at this time.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold',fontFamily:'Surt'}}>If I just want a DQ Assessment do I need to get Powered by DQ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            No. If you just want to use our pre-existing DQ Assessments, you can contact us to purchase licenses to start immediately.
                                        </li>
                                        <li>
                                            If you want a custom assessment, then yes, you will need to go through the DQ Seal process and get Powered by DQ.

                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold',fontFamily:'Surt'}}>Are my program materials and company information protected and safe on your platform?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            Yes, we take our partners’ IP, data, and privacy seriously. You can be assured that your information will be secured and never released or visible to anyone.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <Box mt={7}>
                        <Copyright />
                    </Box>
                </Grid>

            </div>
        </>
    );
}

export default withRouter(Login);
import React from 'react';
import Bg5 from "../../../../assets/images/report/bg5.png";
import Bg11 from "../../../../assets/images/report/bg11.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {DQ_DEFINITIONS} from "../../../../utils/constants";

function OrgDetailRow(props) {
    const {title, value, values} = props;
    return <tr>
        <td style={{paddingRight: 20, verticalAlign: "text-top", width: "150px"}}>
            <Typography variant={'h4'} style={{color: "deepskyblue", fontWeight: "bold", fontSize: "18px", textAlign: "right"}}>
                {title}
            </Typography>
        </td>
        {
            values ?
                <td style={{verticalAlign: "text-top"}}>
                    <ul>
                        {
                            values.map((val) =>
                                <li>
                                    <Typography style={{color: "orangered", fontWeight: "bold", fontSize: "18px", textAlign: "left"}}>
                                        {val}
                                    </Typography>
                                </li>
                            )
                        }
                    </ul>
                </td>
            :
                <td style={{paddingLeft: 20, verticalAlign: "text-top"}}>
                    <Typography style={{color: "grey", fontWeight: "lighter", fontSize: "18px", textAlign: "left"}}>
                        {value}
                    </Typography>
                </td>
        }
    </tr>
}

function AlignedDQBox(props) {

    const {number, title, color} = props;

    return <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} bgcolor={color} p={1} width={'65%'}>
        <Typography align={'center'} variant={"h4"} style={{color: "white", fontWeight: "bold", fontFamily: `"Lemon/Milk"`, flexBasis: "30%"}}>
            {number}
        </Typography>
        <Typography align={'left'} style={{color: "white", fontSize: 16, flexBasis: "70%"}}>
            {title}
        </Typography>
    </Box>
}

export default function Page11(props) {

    const {pageNumber, programInfo, breadth, onlyTable = false} = props;

    function getDQ(dqc_no) {
        const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
        const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
        const num = isNaN(dq_num) ? dqc_no : dq_num;
        const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
        const color = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].color : "#444"
        return {code, title, num, color};
    }

    const aligned_dqc = breadth.data ?
        breadth.data.map((item) => {
            const {dqc_no} = item;
            return getDQ(dqc_no);
        })
    : [];

    return <ReportPage pageNumber={pageNumber} backgroundImage={onlyTable ? Bg5 : Bg11}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} height={'inherit'} width={'inherit'} gridRowGap={10}>
            {
                <Box flexBasis={'10%'} px={10}>
                    <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 70}}>
                        DQ Seal: <Typography variant={'h4'} component='span' style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>
                        {programInfo.ProgramName}
                    </Typography>
                    </Typography>
                </Box>
            }
            <Box flexBasis={'90%'} display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justify={'center'} justifyContent={"center"} width={'inherit'}>
                <Box display={'flex'} flexDirection={'column'} flexBasis={'30%'} ml={"auto"} mb={2} height={'100%'} width={'inherit'}>
                    {
                        !onlyTable &&
                        <Box flexBasis={'55%'}/>
                    }
                    <Box flexBasis={'45%'}>
                        {
                            aligned_dqc.map(({num, title, color}) =>
                                <AlignedDQBox number={num} title={title} color={color}/>
                            )
                        }
                    </Box>
                </Box>
                <Box flexBasis={'60%'} alignSelf={onlyTable ? 'flex-start' : 'center'}>
                    <table style={{borderCollapse: "separate", borderSpacing: "5px"}}>
                        {
                            !onlyTable &&
                            <>
                                <OrgDetailRow title={'Program Name'} value={programInfo.ProgramName}/>
                                <OrgDetailRow title={'Target'} value={programInfo.ProgramTarget}/>
                                <OrgDetailRow title={'Description'} value={programInfo.ProgramDescription}/>
                                <OrgDetailRow title={'Venue'} value={programInfo.ProgramVenue}/>
                                <OrgDetailRow title={''} value={''}/>
                            </>
                        }
                        <OrgDetailRow title={onlyTable ? '' : 'Aligned DQ Areas'} values={aligned_dqc.map(({code, title}) => `${code} ${title}`)}/>
                    </table>
                </Box>
            </Box>
        </Box>
    </ReportPage>
}
import React from 'react';
import Bg3 from "../../../../assets/images/report/bg3.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";

export default function Page3(props) {

    const {pageNumber} = props;

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg3}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} gridColumnGap={"3%"} px={10}>
            <Box display={'flex'} flexDirection={'column'} flexBasis={"35%"}>
                <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 70}}>
                    About: <Typography variant={'h4'} component='span' style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>DQ Seal</Typography>
                </Typography>
                <Box style={{marginTop: "33%"}}>
                    <Typography style={{color: 'grey', fontWeight: "lighter", fontSize: "18px", marginBottom: "30px"}}>
                        The DQ Seal indicates that your program delivers
                        high-quality learning experiences to users that are
                        either 1) aligned with, 2) developed or enhanced
                        based on, or 3) measured and certified by one or
                        more of the 32 digital competencies in the
                        <Typography component={'span'} style={{color: "inherit", fontWeight: "bold", fontSize: "inherit"}}> DQ
                            Global Standards (IEEE&trade; 3527.1)
                        </Typography>
                        . The DQ Seal
                        will enhance the quality, visibility, and reach of
                        your program
                    </Typography>
                    <Typography style={{color: 'grey', fontWeight: "bold", fontSize: "18px"}}>
                        With the DQ Seal, your program is
                        <Typography component='span' style={{color: "skyblue", fontWeight: "inherit", fontStyle: "italic"}}> Powered by DQ </Typography>
                        and will be part of the global coordination project
                        <Typography component='span' style={{color: "inherit", fontWeight: "normal", fontSize: "inherit"}}> to achieve our collective mission of digital
                            empowerment for all.
                        </Typography>
                    </Typography>
                </Box>
            </Box>
            <Box display={'flex'} flexBasis={"65%"} alignSelf={'flex-end'} mb={8} ml={5} justifyContent={'space-evenly'} style={{textAlign: "center"}}>
                <Typography style={{color: "orangered", fontWeight: "normal", display: "flex", flexBasis: "25%"}}>
                    The program is aligned
                    with single or multiple
                    competencies within
                    the DQ Global
                    Standards (IEEE&trade;
                    3527.1).
                </Typography>
                <Typography style={{color: "orangered", fontWeight: "normal", display: "flex", flexBasis: "25%"}}>
                    The program is
                    developed or enhanced
                    based on the DQ Global
                    Standards (IEEE&trade;
                    3527.1).
                </Typography>
                <Typography style={{color: "orangered", fontWeight: "normal", display: "flex", flexBasis: "25%"}}>
                    The program is
                    developed or enhanced
                    based on the DQ Global
                    Standards (IEEE&trade;
                    3527.1) and includes DQ
                    common assessment
                    and analytic tools to
                    measure learning
                    efficacy.
                </Typography>
            </Box>
        </Box>
    </ReportPage>
}
import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useReactToPrint} from 'react-to-print';

import Page1 from "../components/report/Page1.report.comp";
import Page2 from "../components/report/Page2.report.comp";
import Page3 from "../components/report/Page3.report.comp";
import Page4 from "../components/report/Page4.report.comp";
import Page5 from "../components/report/Page5.report.comp";
import Page6 from "../components/report/Page6.report.comp";
import Page7 from "../components/report/Page7.report.comp";
import Page8 from "../components/report/Page8.report.comp";
import Page9 from "../components/report/Page9.report.comp";
import Page10 from "../components/report/Page10.report.comp";
import Page11 from "../components/report/Page11.report.comp";
import Page12 from "../components/report/Page12.report.comp";
import Page13 from "../components/report/Page13.report.comp";
import Page14 from "../components/report/Page14.report.comp";
import Page15 from "../components/report/Page15.report.comp";
import Page16 from "../components/report/Page16.report.comp";
import Page17 from "../components/report/Page17.report.comp";
import Page18 from "../components/report/Page18.report.comp";
import Page19 from "../components/report/Page19.report.comp";
import {useDQCCoverage} from "../hooks/useDQCCoverage.hook";
import {useDepth} from "../hooks/useDepth.hook";
import {useBreadth} from "../hooks/useBreadth.hook";
import Box from "@material-ui/core/Box";
import {APP_STATUS, DQ_DEFINITIONS, SEAL_TYPE} from "../../../utils/constants";
import {asciiSortCompareFunWithKey, getMapFromObjectListByKey, varChunks} from "../../../utils/utils";
import Page16a from "../components/report/Page16a.report.comp";
import {getSampleInstruments} from "../../../services/app.service";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const useStyles = makeStyles((theme) => ({

}))

let enhancement1PageNo=0;
let enhancement2PageNo=0;
let enhancement3PageNo=0;

function varChunksWithFallback(arr, n) {
    if(!arr.length) return [[]];
    return varChunks(arr, n);
}

const Report = forwardRef(function Report(props, ref) {

    const {application_id, programInfo, companyInfo, setLoading} = props;

    const tableContents = useRef({});

    //coverage of dqc
    const [DQCCoverage, setDQCCoverage] = useState({});
    const [DQCCoverageMap, setDQCCoverageMap] = useState({});
    useDQCCoverage(application_id, onSetCoverage);
    //depth
    const [depth, setDepth] = useState({});
    useDepth(application_id, onSetDepth);
    //breadth
    const [breadth, setBreadth] = useState({});
    useBreadth(application_id, onSetBreadth);
    //sample questions
    const [sampleInstruments, setSampleInstruments] = useState([]);
    const [remainingCodes, setRemainingCodes] = useState([]);

    async function fetchSampleInstruments(competencies) {
        const instruments = [];
        setLoading(true);
        try {
            const result = await getSampleInstruments(competencies);
            if(result.data) {
                instruments.push(...result.data);
            }
        } catch (e) {
            console.error(e);
            // already toasted the error
        }
        setLoading(false);
        return instruments;
    }

    function onSetCoverage(object) {
        setDQCCoverage(object);
        if(object.data) {
            const map = getMapFromObjectListByKey(object.data , "dqc_no");
            setDQCCoverageMap(map);
            fetchSampleInstruments(Object.keys(map)).then((res) => setSampleInstruments(res));
        }
    }

    function onSetDepth(object) {
        setDepth(object);
        //calculate remaining codes
        function getDQCode(dqc_no) {
            const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
            return isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
        }
        const aligned_codes_to_filter = object.data ? object.data.map((item) => getDQCode(item.dqc_no)) : []
        const additional_codes = Array.from({ length: 16 }, (_, i) => `DQ${i + 9}`); // DQ9 to DQ24
        const codes_to_filter = [...new Set([...aligned_codes_to_filter, ...additional_codes])];
        const codes = Object.keys(DQ_DEFINITIONS).filter((code) => !codes_to_filter.includes(code)).map((code) => `${code}: ${DQ_DEFINITIONS[code].title}`)
        setRemainingCodes(codes);
    }

    function onSetBreadth(object) {
        setBreadth(object);
    }

    let pageNumber = 1;
    function getPageNumber(content = undefined) {
        if(content) {
            if(content==="Enhancement-I")       { enhancement1PageNo=pageNumber; }
            else if (content==="Enhancement-II"){ enhancement2PageNo=pageNumber; }
            else if(content==="Enhancement-III"){enhancement3PageNo=pageNumber;}
            else {
                tableContents.current = {...tableContents.current, [content]: pageNumber}
            }
        }
        return pageNumber++;
    }

    function getAnalysisData(analysis, filter_function = () => true) {
        return analysis.data ? analysis.data.filter(filter_function) : [];
    }

    function getChunkedAnalysis(analysis, total_chunks, chunk, index) {
        if(analysis.data) {
            const is_last = index === (total_chunks - 1);
            const grand_total = is_last ? analysis.grand_total : undefined;
            return {...analysis, data: chunk, grand_total};
        }
        return {};
    }

    const enhancementPageNumbers = {
        enhancement1PageNo,
        enhancement2PageNo,
        enhancement3PageNo,
    }

    const defaultProps = {application_id};

    return <div ref={ref}>
        <Page1 pageNumber={getPageNumber()} companyInfo={companyInfo}{...defaultProps}/>
        <Page2 pageNumber={getPageNumber()} contents={tableContents.current} {...defaultProps}/>
        <Page3 pageNumber={getPageNumber('About')} {...defaultProps}/>
        <Page4 pageNumber={getPageNumber()} {...defaultProps}/>
        <Page5 pageNumber={getPageNumber()} {...defaultProps}/>
        <Page6 pageNumber={getPageNumber()} companyInfo={companyInfo} programInfo={programInfo} {...defaultProps}/>
        <Page7 pageNumber={getPageNumber()} programInfo={programInfo} {...defaultProps}/>
        {
            [...varChunksWithFallback(getAnalysisData(DQCCoverage), [5, 8])].map((chunk, index, chunks) =>
                <Page8
                    overallCoverage={DQCCoverage}
                    pageNumber={getPageNumber(!index ? 'Analysis' : undefined)}
                    coverage={getChunkedAnalysis(DQCCoverage, chunks.length, chunk, index)}
                    onlyTable={!!index} {...defaultProps}
                />
            )
        }
        {
            [...varChunksWithFallback(getAnalysisData(depth), [6])].map((chunk, index, chunks) =>
                <Page9
                    depth={getChunkedAnalysis(depth, chunks.length, chunk, index)}
                    coverageMap={DQCCoverageMap}
                    pageNumber={getPageNumber()}
                    onlyTable={!!index} {...defaultProps}
                />
            )
        }
        {
            [...varChunksWithFallback(getAnalysisData(breadth), [7])].map((chunk, index, chunks) =>
                <Page10
                    breadth={getChunkedAnalysis(breadth, chunks.length, chunk, index)}
                    coverageMap={DQCCoverageMap}
                    pageNumber={getPageNumber()}
                    onlyTable={!!index} {...defaultProps}
                />
            )
        }
        {
            [...varChunksWithFallback(getAnalysisData(breadth, (item) => item.aligned), [4, 10])].map((chunk, index, chunks) =>
                <Page11
                    programInfo={programInfo}
                    breadth={getChunkedAnalysis(breadth, chunks.length, chunk, index)}
                    pageNumber={getPageNumber(!index ? 'DQ Seal' : undefined)}
                    onlyTable={!!index} {...defaultProps}
                />
            )
        }
        <Page12 pageNumber={getPageNumber('Areas of Enhancement')} {...enhancementPageNumbers} {...defaultProps}/>
        {
            [...varChunksWithFallback(getAnalysisData(depth), [6])].map((chunk, index, chunks) =>
                <Page13
                    depth={getChunkedAnalysis(depth, chunks.length, chunk, index)}
                    coverageMap={DQCCoverageMap}
                    pageNumber={getPageNumber('Enhancement-I')}
                    onlyTable={!!index} {...defaultProps}
                />
            )
        }
        {
            [...varChunksWithFallback(getAnalysisData(breadth, (item) => item.count <= 2), [10])].map((chunk, index, chunks) =>
                <Page14
                    breadth={getChunkedAnalysis(breadth, chunks.length, chunk, index)}
                    pageNumber={getPageNumber('Enhancement-II')}
                    onlyTable={!!index} {...defaultProps}
                />
            )
        }
        {
            [...varChunksWithFallback(remainingCodes, [6, 8])].map((chunk, index) =>
                <Page15
                    codes={chunk}
                    pageNumber={getPageNumber('Enhancement-III')}
                    onlyTable={!!index} {...defaultProps}
                />
            )
        }
        <Page16 pageNumber={getPageNumber()} {...defaultProps}/>
        {
            [...varChunksWithFallback(getAnalysisData(DQCCoverage).sort(asciiSortCompareFunWithKey('dqc_no')), [5, 5])].map((chunk, index) =>
                <Page16a
                    coverage={chunk}
                    instruments={sampleInstruments}
                    pageNumber={getPageNumber()}
                    onlyTable={!!index} {...defaultProps}
                />
            )
        }
        <Page17 pageNumber={getPageNumber('About Us')} {...defaultProps}/>
        <Page18 pageNumber={getPageNumber('Using the DQ Brand')} {...defaultProps}/>
        <Page19 pageNumber={getPageNumber('Contact Us')} {...defaultProps}/>
    </div>
})

function ReportModule(props) {

    const {app, application_id, updateStatus, status} = props;

    const classes = useStyles();
    const componentRef = useRef();
    const [loading, setLoading] = useState(false);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleGetCertify = () => {
        updateStatus(APP_STATUS.READY_TO_CERTIFY, true);
    }

    return (
        <>
            <Box my={2} align={'center'} display={'flex'} justifyContent={"center"} gridColumnGap={10}>
                <Button variant="contained" onClick={handlePrint}  size="large" style={{padding:'10px 40px'}}>Print</Button>
                {
                    app.dqseal_type === SEAL_TYPE.CERTIFY &&
                    status < APP_STATUS.READY_TO_CERTIFY &&
                    <Button variant="contained" onClick={handleGetCertify} size="large" style={{backgroundColor:'#EE6628', padding:'10px 30px'}}>Get Certified</Button>
                }
            </Box>
            <Report ref={componentRef} setLoading={setLoading} {...props}/>
            <Box my={2} align={'center'} display={'flex'} justifyContent={"center"} gridColumnGap={10}>
                <Button variant="contained" onClick={handlePrint} size="large" style={{padding:'10px 40px'}}>Print</Button>
                {
                    app.dqseal_type === SEAL_TYPE.CERTIFY &&
                    status < APP_STATUS.READY_TO_CERTIFY &&
                    <Button variant="contained" onClick={handleGetCertify} size="large" style={{backgroundColor:'#EE6628', padding:'10px 30px'}}>Get Certified</Button>
                }
            </Box>
            <LoaderWithBackDrop loading={loading} />
        </>
    )
}

export default ReportModule;
export const PROGRAM_VENUE = [
    {
        "value": "Companypremises",
        "label": "Company premises"
    },
    {
        "value": "Computerlab",
        "label": "Computer laboratory"
    },
    {
        "value": "Homebased",
        "label": "Home-based"
    },
    {
        "value": "Mobilephonebased",
        "label": "Mobile phone-based"
    },
    {
        "value": "Outdoorpremises",
        "label": "Outdoor premises"
    },
    {
        "value": "PCMacbased",
        "label": "PC/Mac-based"
    },
    {
        "value": "Schools",
        "label": "Schools"
    },
    {
        "value": "TrainingCentre",
        "label": "Training Centre"
    }
];

export const PROGRAM_VENUE_MAP = {
    "Companypremises": {
        "label": "Company premises"
    },
    "Computerlab": {
        "label": "Computer laboratory"
    },
    "Homebased": {
        "label": "Home-based"
    },
    "Mobilephonebased": {
        "label": "Mobile phone-based"
    },
    "Outdoorpremises": {
        "label": "Outdoor premises"
    },
    "PCMacbased": {
        "label": "PC/Mac-based"
    },
    "Schools": {
        "label": "Schools"
    },
    "TrainingCentre": {
        "label": "Training Centre"
    }
};
import React from 'react';
import Bg5 from "../../../../assets/images/report/bg5.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {makeStyles} from "@material-ui/core/styles";
import {DQ_DEFINITIONS} from "../../../../utils/constants";
import {asciiSortCompareFunWithKey} from "../../../../utils/utils";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        "& th,td": {
            border: "1px solid black",
            padding: "5px 10px",
            fontSize: "18px"
        },
        "& th": {
            padding: "18px 12px",
            textAlign: "center",
            backgroundColor: "deepskyblue",
            color: "white",
        },
        "& th:nth-child(1)": {
            width: "10%"
        },
        "& th:nth-child(2),": {
            width: "70%"
        },
        "& th:nth-child(3)": {
            width: "20%"
        },
    },
}));

const badges = [
    {code: 'DQ1', title: 'Digital Citizen Identity', mb: 1, count: 6, aligned: false},
    {code: 'DQ2', title: 'Balanced Digital Use', mb: 1, count: 9, aligned: false},
    {code: 'DQ4', title: 'Personal Cyber Security Management', mb: 2, count: 15, aligned: true},
    {code: 'DQ5', title: 'Digital Empathy', mb: 2, count: 9, aligned: true},
    {code: 'DQ7', title: 'Media and Information Literacy', mb: 1, count: 21, aligned: true},
    {code: 'DQ8', title: 'Privacy Management', mb: 1, count: 7, aligned: false},
    {code: 'DQ25', title: 'Digital User Identity', mb: 1, count: 22, aligned: true}
]

function BadgeRow(props) {
    const {badge} = props;

    const badge_style = {fontWeight: "normal"}
    const aligned_badge_style = {backgroundColor: "#dcf404", fontWeight: "bold", color: "black"}
    const center_text_style = {textAlign: "center"}

    const style_to_use = badge.aligned ? aligned_badge_style : badge_style;
    return <>
        <tr>
            <td style={style_to_use}>{badge.code}</td>
            <td style={style_to_use}>{badge.title}</td>
            <td style={{...style_to_use, ...center_text_style}}>{badge.mb}</td>
        </tr>
    </>
}

export default function Page14(props) {

    const {pageNumber, breadth, onlyTable = false} = props;

    const classes = tableStyles();

    function getDQ(dqc_no) {
        const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
        const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
        const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
        return {code, title};
    }

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg5}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} height={'inherit'} width={'inherit'} gridRowGap={10}>
            {
                <Box flexBasis={'10%'} px={10}>
                    <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 70}}>
                        Enhancement 2: <Typography variant={'h4'} component='span' style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>
                        Increase the Breadth of Learning Messages
                    </Typography>
                    </Typography>
                </Box>
            }
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={"space-around"} height={"inherit"} width={'inherit'} px={5}>
                <Box flexBasis={'40%'}>
                    {
                        !onlyTable &&
                        <Typography style={{fontSize: 28, color: "grey", fontWeight: "lighter", textAlign: "left"}}>
                            {/*Although the program touched on these*/}
                            {/*DQ competencies, there were only one*/}
                            {/*or two MBs aligned in each of them.*/}
                            {/*More DQ MBs can be taught to enhance*/}
                            {/*the quality of the learning program.*/}
                            <p>Although the program touched on these DQ competencies (white rows), there were insufficient GSMs aligned in each of them. More DQ GSMs can be taught to enhance the quality of the learning program.</p>
                            <p>By focusing on acquiring more aligned competencies, your program can continue to expand its reach and impact on a global scale.</p>
                        </Typography>
                    }
                </Box>
                <Box flexBasis={'60%'} alignSelf={onlyTable ? "flex-start" : "inherit"}>
                    <table className={classes.root} style={{width: "inherit"}}>
                        <tr>
                            <th>DQ#</th>
                            <th>DQ Competencies</th>
                            <th># of Aligned MBs</th>
                        </tr>
                        {
                            breadth.data &&
                            breadth.data.map((b) => <BadgeRow badge={{
                                ...getDQ(b.dqc_no), //gets code and name
                                mb: b.count,
                                aligned: b.aligned
                            }}/>)
                        }
                    </table>
                </Box>
            </Box>
        </Box>
    </ReportPage>
}
export const PROGRAM_DURATION = [
    {
        "value": "lessthan15min",
        "label": "lessthan15min"
    },
    {
        "value": "15-30min",
        "label": "15-30min"
    },
    {
        "value": "30min-1hr",
        "label": "30min-1hr"
    },
    {
        "value": "1hour",
        "label": "1 hour"
    },
    {
        "value": "2hours",
        "label": "2 hours"
    },
    {
        "value": "3-5hours",
        "label": "3-5 hours"
    },
    {
        "value": "6-7hours",
        "label": "6-7 hours"
    },
    {
        "value": "8-10hours",
        "label": "8-10 hours"
    },
    {
        "value": "11-15hours",
        "label": "11-15 hours"
    },
    {
        "value": "16-20hours",
        "label": "16-20 hours"
    },
    {
        "value": "morethan20hours",
        "label": "More than 20 hours"
    }
];

export const PROGRAM_DURATION_MAP = {
    "lessthan15min": {
        "label": "lessthan15min"
    },
    "15-30min": {
        "label": "15-30min"
    },
    "30min-1hr": {
        "label": "30min-1hr"
    },
    "1hour": {
        "label": "1 hour"
    },
    "2hours": {
        "label": "2 hours"
    },
    "3-5hours": {
        "label": "3-5 hours"
    },
    "6-7hours": {
        "label": "6-7 hours"
    },
    "8-10hours": {
        "label": "8-10 hours"
    },
    "11-15hours": {
        "label": "11-15 hours"
    },
    "16-20hours": {
        "label": "16-20 hours"
    },
    "morethan20hours": {
        "label": "More than 20 hours"
    }
};
import React from 'react';
import Bg2 from "../../../../assets/images/report/bg2.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {asciiSortCompareFunWithKey} from "../../../../utils/utils";

function TableContent(props) {
    const {title, value} = props;
    return  <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={"60%"}>
        <Typography variant={'h4'} style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>
            {title}
        </Typography>
        <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>
            {value}
        </Typography>
    </Box>
}

export default function Page2(props) {

    const {pageNumber, contents} = props;

    const contents_array = Object.entries(contents).map(([title, page_no]) => ({title, page_no}))
    const numeric_asc_sort_fun = (a, b) => a.page_no - b.page_no;
    contents_array.sort(numeric_asc_sort_fun);

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg2}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={"center"} height={"inherit"}
             style={{marginLeft: "50%"}} width={"50%"} gridRowGap={20}>
            <Typography variant={'h3'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>
                Table of Contents
            </Typography>
            <Box mt={5}/>
            {
                contents_array.map((item) =>
                    <TableContent title={item.title} value={item.page_no}/>
                )
            }
            {/*<TableContent title={'Analysis'} value={'08'}/>*/}
            {/*<TableContent title={'DQ Seal'} value={'11'}/>*/}
            {/*<TableContent title={'Areas of Enhancement'} value={'12'}/>*/}
            {/*<TableContent title={'About Us'} value={'17'}/>*/}
            {/*<TableContent title={'Using the DQ Brand'} value={'18'}/>*/}
            {/*<TableContent title={'Contact Us'} value={'19'}/>*/}
        </Box>
    </ReportPage>
}
import {useEffect, useState} from 'react';
import {getAppDetailService} from "../../../services/app.service";
import {getCompanyInfoService} from "../../../services/company.service";

export function useAppDetails(application_id) {

    const [appDetails, setAppDetails] = useState({});

    const loadAppDetails = async () => {
        try {
            const result = await getAppDetailService(application_id);
            const result2 = await getCompanyInfoService();
            if (!result.error && !result2.error) {
                const program_info = result.data.program_info;
                const company_info = result2.data.comp;

                let details = {programInfo: {}, companyInfo: {}};
                if (program_info) {
                    details.programInfo = programMap(program_info);
                }
                if(company_info) {
                    details.companyInfo = companyMap(company_info);
                }
                setAppDetails(details);
            }
        } catch (e) {
            // already toasted the error
        }
    }

    useEffect( ()=> {
        if(application_id) {
            loadAppDetails().then((res) => `app details fetch initiated, ${res}`);
        }
    }, [])

    return appDetails;
}

function programMap(program_info) {
    return {
        ProgramName: program_info.program_name,
        ProgramType: program_info.program_type,
        ProgramTypeOther: program_info.program_type_other,
        ProgramAims: program_info.program_aims,
        ProgramTarget: program_info.program_target,
        TargetAge: program_info.target_age,
        TargetCountry: program_info.target_country,
        ProgramDescription: program_info.program_description,
        ProgramDelivery: program_info.program_delivery,
        ProgramVenue: program_info.program_venue,
        ProgramDuration: program_info.program_duration,
        RefResource: program_info.ref_resource,
        StartYear: program_info.start_year,
        Status: program_info.status,
        Links: program_info.links,
        ProgramPrice: program_info.program_price,
        Success: program_info.success,
        SuccessMeasure: program_info.success_measure,
        KPITrack: program_info.kpi_track,
        RefLink: program_info.ref_link,
        RefFramework: program_info.ref_framework,
    }
}

function companyMap(company_info) {
    return {
        NameOrg: company_info.name_org,
        NameContact: company_info.name_contact,
        NameDept: company_info.name_dept,
        Region: company_info.region,
        OrgType: company_info.org_type,
        Sector: company_info.sector,
        SectorEntered: company_info.sector_entered,
        History: company_info.history,
        SizeEmployee: company_info.size_employee,
        OrgVision: company_info.org_vision,
    }
}
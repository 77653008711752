import React from 'react';
import Bg5 from "../../../../assets/images/report/bg5.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {makeStyles} from "@material-ui/core/styles";
import {asciiSortCompareFunWithKey, getMapFromObjectListByKey} from "../../../../utils/utils";
import {DQ_DEFINITIONS} from "../../../../utils/constants";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        "& th,td": {
            border: "1px solid black",
            padding: "5px 10px",
            fontSize: "18px"
        },
        "& th": {
            padding: "18px 12px",
            textAlign: "center",
            backgroundColor: "deepskyblue",
            color: "white",
        },
        "& th:nth-child(1)": {
            width: "10%"
        },
        "& th:nth-child(2),": {
            width: "35%"
        },
        "& th:nth-child(3)": {
            width: "15%"
        },
        "& th:nth-child(4)": {
            width: "15%"
        },
        "& th:nth-child(5)": {
            width: "15%"
        }
    },
}));

const badges = [
    {code: 'DQ1', title: 'Digital Citizen Identity', mb: 1, count: 6, aligned: false},
    {code: 'DQ2', title: 'Balanced Digital Use', mb: 1, count: 9, aligned: false},
    {code: 'DQ4', title: 'Personal Cyber Security Management', mb: 2, count: 15, aligned: true},
    {code: 'DQ5', title: 'Digital Empathy', mb: 2, count: 9, aligned: true},
    {code: 'DQ7', title: 'Media and Information Literacy', mb: 1, count: 21, aligned: true},
    {code: 'DQ8', title: 'Privacy Management', mb: 1, count: 7, aligned: false},
    {code: 'DQ25', title: 'Digital User Identity', mb: 1, count: 22, aligned: true}
]

function BadgeRow(props) {
    const {badge} = props;

    const badge_style = {fontWeight: "normal"}
    const aligned_badge_style = {backgroundColor: "#dcf404", fontWeight: "bold", color: "black"}
    const center_text_style = {textAlign: "center"}

    const style_to_use = badge.aligned ? aligned_badge_style : badge_style;
    return <>
        <tr>
            <td style={style_to_use}>{badge.code}</td>
            <td style={style_to_use}>{badge.title}</td>
            <td style={{...style_to_use, ...center_text_style}}>{badge.mb}</td>
            <td style={{...style_to_use, ...center_text_style}}>{badge.count}</td>
            <td style={{...style_to_use, ...center_text_style}}>{badge.aligned ? "Yes" : "No"}</td>
        </tr>
    </>
}

export default function Page10(props) {

    const {pageNumber, breadth, coverageMap, onlyTable = false} = props;

    function getDQ(dqc_no) {
        const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
        const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
        const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
        return {code, title};
    }

    const classes = tableStyles();

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg5}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} height={'inherit'} width={'inherit'} gridRowGap={10}>
            {
                <Box flexBasis={'10%'} px={10}>
                    <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 70}}>
                        DQ Seal Analysis III: <Typography variant={'h4'} component='span' style={{color: "grey", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`}}>
                        Breadth of Learning Messages Meeting DQ Competency Standards
                        </Typography>
                    </Typography>
                </Box>
            }
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justify={'flex-end'} justifyContent={"flex-end"} height={"inherit"} width={'inherit'} gridColumnGap={20}>
                <Box flexBasis={'60%'} pl={10} alignSelf={onlyTable ? "flex-start" : "inherit"}>
                    <table className={classes.root} style={{width: "inherit"}}>
                        <tr>
                            <th>DQ#</th>
                            <th>Digital Competency</th>
                            <th># of Aligned GSMs</th>
                            <th># of Learning Messages</th>
                            <th>Aligned<span style={{fontStyle: "italic"}}>(DQ Competency)</span></th>
                        </tr>
                        {
                            breadth.data &&
                            breadth.data.map((b) => <BadgeRow badge={{
                                ...getDQ(b.dqc_no), //gets code and name
                                mb: b.count,
                                count: coverageMap[b.dqc_no] ? coverageMap[b.dqc_no].count : 0,
                                aligned: b.aligned
                            }}/>)
                        }
                    </table>
                </Box>
                <Box flexBasis={'40%'}>
                    {
                        !onlyTable &&<div>
                        <Box style={{backgroundColor: "#444", borderTopLeftRadius: "20px", padding: 20}}>
                            <Typography style={{fontSize: 22, color: "white", fontWeight: "lighter", fontStyle: "italic"}}>
                                {/**Breadth of learning messages refers to*/}
                                {/*the extent of content coverage of the*/}
                                {/*DQ MBs within each DQ competency*/}
                                Breadth of learning messages refers to the extent of content coverage of the DQ MBs within each DQ competency.
                            </Typography>
                        </Box>
                            <Box style={{backgroundColor: "#0000", borderTopLeftRadius: "20px", padding: 20}}>
                                <Typography style={{fontSize: 18, color: "black", fontWeight: "lighter"}}>
                                    <p>This analysis evaluates the overall breadth of topic coverage with your program, in addition to the GSMs earned from the previous depth analysis. This helps you identify the digital competency that best aligns with your program’s learning objectives, enabling your organization to position the program more effectively within the DQ framework.</p>
                                    <p>This strategic positioning empowers your program to capitalize on the universally recognized structure and allows you to systematically promote your program.</p>
                                </Typography>
                            </Box>
                        </div>
                    }
                </Box>
            </Box>
        </Box>
    </ReportPage>
}
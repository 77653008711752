import React from 'react';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {Grid, Typography} from "@material-ui/core";
import {PROGRAM_TYPES_MAP} from "../../../utils/META/cat_106";
import {PROGRAM_TARGETS_MAP} from "../../../utils/META/cat_108";
import {TARGET_AGE_MAP} from "../../../utils/META/cat_109";
import {PROGRAM_VENUE_MAP} from "../../../utils/META/cat_111";
import {PROGRAM_DURATION_MAP} from "../../../utils/META/cat_112";
import {PROGRAM_STATUS_MAP} from "../../../utils/META/cat_121";
import {SEAL_TYPES_MAP} from "../../../utils/META/cat_seal_types";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
    },
    label: {
        textAlign: 'right',
        marginRight: theme.spacing(2),
        wordBreak: "break-word"
    },
    value: {
        textAlign: 'left',
        marginTop: theme.spacing(0.8),
        marginLeft: theme.spacing(2),
        wordBreak: "break-word"
    },
}));

const key_desc = {
    ProgramName: "Program Title",
    ProgramType: "Type of the Program",
    ProgramTypeOther: "More details About Type",
    ProgramAims: "Aims and objectives of program",
    ProgramTarget: "Target audience of program",
    TargetAge: "Target Age group",
    TargetCountry: "Target countries",
    ProgramDescription: "Description of Program",
    ProgramDelivery: "Description of program delivery",
    ProgramVenue: "Venue where program is conducted",
    ProgramDuration: "Time taken for the program to be completed (in hours)",
    RefResource: "Resources required for program to be carried out",
    StartYear: "Year of implementation launch",
    Status: "Status of program",
    Links: "Link or Mobile app",
    ProgramPrice: "Price of the Program (Average Cost per Individual)",
}

const key_val = {
    ProgramType: (value) => PROGRAM_TYPES_MAP[value] && PROGRAM_TYPES_MAP[value].label ? PROGRAM_TYPES_MAP[value].label : value,
    ProgramTarget: (value) => PROGRAM_TARGETS_MAP[value] && PROGRAM_TARGETS_MAP[value].label ? PROGRAM_TARGETS_MAP[value].label : value,
    TargetAge: (value) => TARGET_AGE_MAP[value] && TARGET_AGE_MAP[value].label ? TARGET_AGE_MAP[value].label : value,
    ProgramVenue: (value) => PROGRAM_VENUE_MAP[value] && PROGRAM_VENUE_MAP[value].label ? PROGRAM_VENUE_MAP[value].label : value,
    ProgramDuration: (value) => PROGRAM_DURATION_MAP[value] && PROGRAM_DURATION_MAP[value].label ? PROGRAM_DURATION_MAP[value].label : value,
    Status: (value) => PROGRAM_STATUS_MAP[value] && PROGRAM_STATUS_MAP[value].label ? PROGRAM_STATUS_MAP[value].label : value,
}

function ProgramDescription(props) {

    const {progDesc} = props;

    const classes = useStyles();

    return <Container maxWidth={false} className={classes.container} component={Paper}>
        <Grid container spacing={3}>
            {Object.entries(key_desc).map(([key, value]) => (
                <Grid item xs={12} key={key}>
                    <Grid container justify="center" alignItems="flex-start">
                        <Grid item xs={6}>
                            <Typography variant="h6" className={classes.label}>{value}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" className={classes.value}>{key_val[key] ? key_val[key](progDesc[key]) : progDesc[key]}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    </Container>
}

export default ProgramDescription;

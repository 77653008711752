export const SECTORS = [
    {
        "value": "AerospaceIndustries",
        "label": "Aerospace Industries"
    },
    {
        "value": "AutomotiveIndustries",
        "label": "Automotive Industries"
    },
    {
        "value": "AviationTravelandTourism",
        "label": "Aviation, Travel and Tourism"
    },
    {
        "value": "ChemistryandAdvancedMaterials",
        "label": "Chemistry and Advanced Materials"
    },
    {
        "value": "ConsumerIndustries",
        "label": "Consumer Industries"
    },
    {
        "value": "Education",
        "label": "Education"
    },
    {
        "value": "Electronics",
        "label": "Electronics"
    },
    {
        "value": "Energy",
        "label": "Energy"
    },
    {
        "value": "FinancialServices",
        "label": "Financial Services"
    },
    {
        "value": "HealthHealthcare",
        "label": "Health and Healthcare"
    },
    {
        "value": "InformationCommTech",
        "label": "Information and Communication Technologies"
    },
    {
        "value": "InfraUrbanDev",
        "label": "Infrastructure and Urban Development"
    },
    {
        "value": "Investors",
        "label": "Investors"
    },
    {
        "value": "Manufacturing",
        "label": "Manufacturing"
    },
    {
        "value": "MediaEntInfo",
        "label": "Media, Entertainment and Information"
    },
    {
        "value": "Mobility",
        "label": "Mobility"
    },
    {
        "value": "MiningandMetals",
        "label": "Mining and Metals"
    },
    {
        "value": "Pharma",
        "label": "Pharmaceutical Services"
    },
    {
        "value": "ProfessionalServices",
        "label": "Professional Services"
    },
    {
        "value": "SupplyChainandTransport",
        "label": "Supply Chain and Transport"
    },
    {
        "value": "other",
        "label": "Other"
    }
];

export const SECTORS_MAP = {
    "AerospaceIndustries": {
        "label": "Aerospace Industries"
    },
    "AutomotiveIndustries": {
        "label": "Automotive Industries"
    },
    "AviationTravelandTourism": {
        "label": "Aviation, Travel and Tourism"
    },
    "ChemistryandAdvancedMaterials": {
        "label": "Chemistry and Advanced Materials"
    },
    "ConsumerIndustries": {
        "label": "Consumer Industries"
    },
    "Education": {
        "label": "Education"
    },
    "Electronics": {
        "label": "Electronics"
    },
    "Energy": {
        "label": "Energy"
    },
    "FinancialServices": {
        "label": "Financial Services"
    },
    "HealthHealthcare": {
        "label": "Health and Healthcare"
    },
    "InformationCommTech": {
        "label": "Information and Communication Technologies"
    },
    "InfraUrbanDev": {
        "label": "Infrastructure and Urban Development"
    },
    "Investors": {
        "label": "Investors"
    },
    "Manufacturing": {
        "label": "Manufacturing"
    },
    "MediaEntInfo": {
        "label": "Media, Entertainment and Information"
    },
    "Mobility": {
        "label": "Mobility"
    },
    "MiningandMetals": {
        "label": "Mining and Metals"
    },
    "Pharma": {
        "label": "Pharmaceutical Services"
    },
    "ProfessionalServices": {
        "label": "Professional Services"
    },
    "SupplyChainandTransport": {
        "label": "Supply Chain and Transport"
    },
    "other": {
        "label": "Other"
    }
}
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {splitEveryNthPos} from "../../../../utils/utils";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

function CoverageChart(props) {
    const {coverage} = props;

    const formatted_data = {
        labels: coverage.map((item) => item.title),
        datasets: [{
            label: 'Coverage',
            data: coverage.map((item) => item.percent),
            backgroundColor: coverage.map((item) => item.color),
        }]
    }

    const displayOutsideLabel = coverage.length <= 8;

    const options = {
        layout: {
            padding: {
                left: 100,
                right: 100,
                top: 100,
                bottom: 100
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: !displayOutsideLabel,
                callbacks: {
                    label: function(context) {
                        const item = coverage[context.dataIndex];
                        return `${item.code} ${item.title}: ${item.percent}%`;
                    }
                }
            },
            datalabels: {
                labels: {
                    inside: {
                        color: "black",
                        font: {
                            size: 14
                        },
                        formatter: function(value, context) {
                            return `${coverage[context.dataIndex].count}, ${coverage[context.dataIndex].percent}%`;
                        }
                    },
                    outside: displayOutsideLabel ? {
                        font: {
                            size: 14
                        },
                        anchor: "end",
                        align: "end",
                        formatter: function(value, context) {
                            const title = `${coverage[context.dataIndex].code} ${coverage[context.dataIndex].title}`;
                            return splitEveryNthPos(title, ' ', 8);
                        }
                    }
                    : {
                        font: {
                            size: 14
                        },
                        anchor: "end",
                        align: "end",
                        formatter: function(value, context) {
                            const title = `${coverage[context.dataIndex].code}`;
                            return splitEveryNthPos(title, ' ', 8);
                        }
                    }
                    ,
                }
            }
        }
    }

    return <Pie data={formatted_data} options={options}/>
}

export default CoverageChart;
import React from 'react';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    background: {
        width: "inherit",
        height: "inherit",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        // border: "#000 solid 2px"
    },
    // background_image: {
    //     width: "100%",
    //     maxWidth: "1280px",
    //     height: "auto",
    // },
    // page_container: {
    //     borderStyle: "solid", borderWidth: "2px", borderColor: "lightgrey"
    // },
}))

export default function PageContainer(props) {

    const classes = useStyles();
    const {pageNumber, backgroundImage, children} = props;

    console.log("Page Number", pageNumber);

    return <div className={'print-page-break'}>
        <Box display={'flex'} justifyContent={'center'}>
            <Grid container direction="column" alignItems="center">
                <Grid className={'page-outline'} item style={{width: 1280, height: 730}}>
                    <div className={classes.background} style={{backgroundImage: `url(${backgroundImage})`, height:700}}>
                        {children}
                        <Box flexDirection={'row'} justifyContent={'center'} alignItems={'flex-start'} className={'print-footer'} mt={20}  mb={3} textAlign={'center'} style={{fontSize: 12}}>
                            <Box ml={"auto"}>
                                ©2023 DQ LAB Pte Ltd. All Rights Reserved
                            </Box>
                            <Box ml={"auto"} style={{float:"right"}} mt={-2} mr={1} mr={3}>
                                {pageNumber}
                            </Box>
                        </Box>
                    </div>
                </Grid>
            </Grid>
        </Box>
    </div>
}
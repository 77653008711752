import React, {useEffect, useState} from 'react';
import TabContainer from "../../../components/Tabs/container.component";
import ProgramDescription from "../components/program_desc.comp";
import ProgramImpact from "../components/program_impact.comp";
import {getAppDetailService} from "../../../services/app.service";

function ApplicationModule(props) {

    const {application_id, programInfo} = props;

    return <TabContainer>
        <ProgramDescription title={'Program Description'} progDesc={programInfo}/>
        <ProgramImpact title={'Program Impact'} progImpact={programInfo}/>
    </TabContainer>
}

export default ApplicationModule;
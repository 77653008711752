import React, { useState, useEffect } from "react";
import {
    Container,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Typography,
    Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, useFormikContext } from "formik";
import {getCompanyInfoService, updateCompanyInfoService} from "../../services/company.service";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../context/auth";
import {REGIONS} from "../../utils/META/cat_101";
import {ORG_TYPES} from "../../utils/META/cat_102";
import {SECTORS} from "../../utils/META/cat_103";
import {HIST_DURATIONS} from "../../utils/META/cat_104";
import {SIZE_EMPLOYEE} from "../../utils/META/cat_105";
import {toast} from "../../utils/utils";
import {TOAST_TYPES} from "../../utils/constants";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";


const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: "100%",
    },
}));

const defaultInitialValues = {
    NameOrg: "Test Organization",
    NameContact: "John Doe",
    NameDept: "Software Development, Software Developer",
    Region: "Singapore",
    OrgType: "EdTech",
    Sector: "AutomotiveIndustries",
    SectorEntered: "",
    History: "lessthan1year",
    SizeEmployee: "11-50employees",
    OrgVision: "Our vision is to revolutionize the IT sector. Our mission is to provide top quality software services."
};

const emptyInitialValues = {
    NameOrg: "",
    NameContact: "",
    NameDept: "",
    Region: "",
    OrgType: "",
    Sector: "",
    SectorEntered: "",
    History: "",
    SizeEmployee: "",
    OrgVision: ""
};

// Your form fields...
const FormTextField = ({ label, name, ...rest }) => (
    <Field
        as={TextField}
        label={label}
        name={name}
        fullWidth
        required
        margin="normal"
        variant="outlined"
        {...rest}
    />

);


const FormSelect = ({ label, name, options }) => (
    <FormControl fullWidth variant="outlined" margin="normal" required>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Field as={Select} label={label} name={name}>
            {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Field>
    </FormControl>
);


const FormComponent = () => {

    const { values } = useFormikContext(); // get current form values

    return (
        <Form>
            <FormTextField label="Name of organization" name="NameOrg" />
            <FormTextField label="Name of contact person" name="NameContact" />
            <FormTextField
                label="Department and position of contact person"
                name="NameDept"
            />
            <FormSelect
                label="Please choose the country of your organization’s headquarters."
                name="Region"
                options={REGIONS}
            />
            <FormSelect
                label="Type of organization"
                name="OrgType"
                options={ORG_TYPES}
            />
            <FormSelect
                label="What sector is your organization in?"
                name="Sector"
                options={SECTORS}
            />
            {values.Sector === 'other' && (
                <FormTextField label="Type your Sector." name="SectorEntered" />
            )}
            <FormSelect
                label="How long has your organization been in this industry?"
                name="History"
                options={HIST_DURATIONS}
            />
            <FormSelect
                label="Number of employees in your organization"
                name="SizeEmployee"
                options={SIZE_EMPLOYEE}
            />
            <FormTextField
                label="Please state your organization's vision and mission."
                name="OrgVision"
            />
            <Box mt={2}>
                <Button type="submit" variant="contained" color="primary">
                    Save
                </Button>
            </Box>
        </Form>
    );
};

const CompanyInfoUpdatePage = () => {
    const classes = useStyles();

    const history = useHistory();
    const { authUser, setAuthUser } = useAuth();
    const [formValues, setFormValues] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const getCompanyInfo = async () => {
        try {
            setLoading(true);
            const result = await getCompanyInfoService();
            if(!result.error) {
                if (result.data.comp) {
                    const infoObject = {
                        NameOrg: result.data.comp.name_org,
                        NameContact: result.data.comp.name_contact,
                        NameDept: result.data.comp.name_dept,
                        Region: result.data.comp.region,
                        OrgType: result.data.comp.org_type,
                        Sector: result.data.comp.sector,
                        SectorEntered: result.data.comp.sector_entered,
                        History: result.data.comp.history,
                        SizeEmployee: result.data.comp.size_employee,
                        OrgVision: result.data.comp.org_vision,
                    };
                    setFormValues(infoObject);
                }
                setLoading(false);
            }
        }
        catch (e) {
            // already toasted the error
            setLoading(false);
        }
    };

    useEffect(() => {
        if (authUser.company_info_exist) {
            getCompanyInfo().then((res) => `fetch initiated, ${res}`);
        } else {
            setFormValues(defaultInitialValues);
        }
    }, [authUser]);

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const result = await updateCompanyInfoService(values);
            console.log('result.data -> ', result.data);
            if (result.data.success) {
                toast(TOAST_TYPES.SUCCESS, "Company Info Updated", "Successfully updated the company information, you can now proceed.")
                await setAuthUser({
                    ...authUser,
                    company_info_exist: true
                });
                history.push('/home');
                setLoading(false);
            } else {
                toast(TOAST_TYPES.DANGER, "Bad Request Data", "some of the info might not be correct, info not updated at this moment, try again.");
                setLoading(false);
            }


        } catch (e) {
            // already toasted the error
            setLoading(false);
        }
    };

    return (
        <Container className={classes.container}>
            <Typography variant="h4" component="h1" gutterBottom>
                Enter Company Info
            </Typography>
            {
                formValues && <Formik initialValues={(process.env.REACT_APP_NAME==='LOCAL') ? formValues : emptyInitialValues

                } onSubmit={handleSubmit} >
                    <FormComponent />
                </Formik>
            }
            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
};

export default CompanyInfoUpdatePage;

import React from 'react';
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ReportPage from "./PageContainer.report.comp";
import {makeStyles} from "@material-ui/core/styles";
import Bg5 from "../../../../assets/images/report/bg5.png";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        width: "100%",
        "& th,td": {
            border: "1px solid grey",
            padding: "8px",
            fontSize: "20px"
        },
        "& th": {
            paddingTop: "12px",
            paddingBottom: "12px",
            textAlign: "center",
            backgroundColor: "#444",
            color: "white",
        }
    },
}));

export default function Page7(props) {

    const {pageNumber, programInfo} = props;
    const classes = tableStyles();

    return <ReportPage pageNumber={pageNumber} backgroundImage={Bg5}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={'inherit'} width={'inherit'} gridRowGap={10} px={10}>
            <Box flexBasis={'10%'}>
                <Typography variant={'h4'} style={{color: "orangered", fontWeight: "lighter", fontFamily: `"Lemon/Milk"`, paddingTop: 70}}>
                    Program
                </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={"center"} my={"auto"} alignSelf={'center'}>
                <table className={classes.root}>
                    <tr>
                        <th >Program Name</th>
                        <th >Program Link</th>
                        <th >Program Description</th>
                        <th >Target Audience</th>
                    </tr>
                    <tr>
                        <td style={{fontWeight: "bold", color: "orangered", fontSize: 28, textAlign: "center"}}>{programInfo.ProgramName}</td>
                        <td>
                            {
                                programInfo.Links &&
                                programInfo.Links.split(' ').map((link) =>
                                    <a href={link} style={{color: "deepskyblue"}}>
                                        {link}
                                    </a>
                                )
                            }
                        </td>
                        <td>{programInfo.ProgramDescription}</td>
                        <td>{programInfo.ProgramTarget}</td>
                    </tr>
                </table>
            </Box>
        </Box>

    </ReportPage>
}